import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase';
import useForm from '../useForm';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import logo from '../assets/remotely-logo-04-cropped.png';
import PublicLanguageChanger from '../components/PublicLanguageChanger';

const SignInHelpPage: React.FC = () => {
  const user = useSelector(getUser);
  const { t, i18n } = useTranslation('translation');

  const sendPasswordResetEmail = async () => {
    try {
      setError('');
      auth.languageCode = i18n.language;
      await auth.sendPasswordResetEmail(values.email);
      setSuccess(t('SignInHelp.SuccessMessage', { url: 'http://localhost:3000/signin?reset=1' }));
    } catch (error) {
      setError(t(`ErrorCodes.${error.code}`));
    }
  };

  const { values, handleChange, handleSubmit }: any = useForm(sendPasswordResetEmail);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  if (user.team) {
    return <Redirect to={{ pathname: '/questions' }} />;
  } else {
    return (
      <>
        <div className="level signin-logo">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                <img src={logo} className="App-logo" alt="Remotely Interview Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="level signin">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                <p className="title has-text-centered">{t('SignInHelp.Title')}</p>
                <p className="subtitle has-text-centered">{t('SignInHelp.SubTitle')}</p>
                <div className="signin-form">
                  <form onSubmit={handleSubmit}>
                    <div className="field">
                      <label className="label">{t('SignInHelp.EmailLabel')}</label>
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          placeholder={t('SignInHelp.EmailPlaceholder')}
                          name="email"
                          value={values.email || ''}
                          onChange={handleChange}
                          data-cy="username"
                        />
                      </div>
                    </div>

                    {error && <div className="notification is-danger">{error}</div>}
                    {success && <div className="notification is-info">{success}</div>}

                    <button className="button is-fullwidth is-primary" type="submit" data-cy="sendRecoveryButton">
                      {t('SignInHelp.SendRecoveryButton')}
                    </button>
                  </form>
                </div>
                <hr className="has-background-grey-light" />
                <div className="has-text-centered">
                  <Link to={`/signin?lang=${i18n.language}`}>{t('SignInHelp.ReturnToSignIn')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PublicLanguageChanger></PublicLanguageChanger>
      </>
    );
  }
};

export default SignInHelpPage;
