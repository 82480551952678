import React, { useContext, useEffect, useState } from 'react';
import { match as Imatch } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { saveTemplate } from '../firebase';
import { TemplatesContext } from '../providers/TemplatesContext';
import { useTranslation } from 'react-i18next';
import PositionOrg from './positionOrg';
import { useSelector } from 'react-redux';
import { getUserTeam } from '../stores/user/selectors';
import { TemplateProps } from '../types';

const PositionPage = () => {
  const userTeam = useSelector(getUserTeam);
  const { templates }: any = useContext(TemplatesContext);
  const [template, setTemplate] = useState<TemplateProps>();
  const [messageHidden, setMessageHidden] = useState<boolean>(
    ((localStorage.getItem('dismissDndMessage') as unknown) as boolean) || false
  );
  const { t } = useTranslation('translation');
  const match: Imatch<{ id: string }> = useRouteMatch();

  useEffect(() => {
    if (!userTeam || !templates) {
      return;
    }

    getTemplateLocal();
    async function getTemplateLocal() {
      const template = templates.find((t: { id: any }) => t.id === match.params.id);
      setTemplate(template);
    }
  }, [match.params.id, userTeam, templates]);

  const handleChange = ({ target }: any) => {
    let { name } = target;
    let value = target.value;

    setTemplate({ ...template, [name]: value });
  };

  const handleNameBlur = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!template.name) {
      return;
    }
    await saveTemplate(userTeam, template);
  };

  const handleDismissDndMessage = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    localStorage.setItem('dismissDndMessage', 'true');
    setMessageHidden(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!template.name) {
      return;
    }

    await saveTemplate(userTeam, template);
  };

  function renderDndMessage() {
    return (
      <article className="message is-info" style={{ minWidth: '300px' }}>
        <div className="message-header">
          <p>{t('Position.DndTitle')}</p>
          <button className="delete" aria-label="delete" onClick={handleDismissDndMessage}></button>
        </div>
        <div className="message-body">{t('Position.DndMessage')}</div>
      </article>
    );
  }

  if (!template) {
    return null;
  }

  return (
    <section>
      <h1 className="title is-2">{t('Position.PositionTitle')}</h1>
      <div className="columns">
        <div className="column is-half">
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">{t('Position.NameLabel')}</label>
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  name="name"
                  value={template.name}
                  onChange={handleChange}
                  onBlur={handleNameBlur}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {!messageHidden && renderDndMessage()}

      <PositionOrg templateId={template.id} />
    </section>
  );
};

export default PositionPage;
