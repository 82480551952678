import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/performance';
import {
  documentSnapshotToQuestion,
  queryDocumentSnapshotToTemplate,
  documentSnapshotToTemplate,
  queryDocumentSnapshotToMember,
  documentSnapshotToInterview,
  queryDocumentSnapshotToInterviewQuestion,
  queryDocumentSnapshotToInterview
} from './utilities';

import { loadStripe } from '@stripe/stripe-js';

import { QuestionProps, TemplateProps, TInterview } from './types';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_APP_ID
};

const stripePK = process.env.REACT_APP_STRIPE_ID;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firebaseConfigApiKey = firebaseConfig.apiKey;
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const perf = firebase.performance();

export const provider = new firebase.auth.GoogleAuthProvider();
export const providerFacebook = new firebase.auth.FacebookAuthProvider();
export const providerTwitter = new firebase.auth.TwitterAuthProvider();
export const providerMicrosoft = new firebase.auth.OAuthProvider('microsoft.com');
providerMicrosoft.setCustomParameters({ tenant: 'b6c15245-1886-4735-a517-5a09ea1f78df' });
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const signInWithFacebook = () => auth.signInWithPopup(providerFacebook);
export const signInWithTwitter = () => auth.signInWithPopup(providerTwitter);
export const signInWithMicrosoft = () => auth.signInWithPopup(providerMicrosoft);
export const signOut = () => auth.signOut();

firestore.enablePersistence().catch(function(err) {
  if (err.code === 'failed-precondition') {
    console.log('enablePersistence Failed - failed-precondition', err);
  } else if (err.code === 'unimplemented') {
    console.log('enablePersistence Failed - unimplemented - browser support', err);
  }
});

//#region General purpose firebase helpers
export const deleteCollection = async (path: string) => {
  const batch = firestore.batch();
  const querySnapshot = await firestore.collection(path).get();

  querySnapshot.docs.forEach(documentSnapshot => {
    const ref = firestore.collection(path).doc(documentSnapshot.id);
    batch.delete(ref);
  });

  await batch.commit();
};
//#endregion

//#region Team and User Methods
// This method will ensure that the logged in user has a user document and belongs to a team
export const createUserProfileDocument = async (
  user: { displayName?: any; email?: any; photoURL?: any; uid?: any },
  additionalData?: any
) => {
  // If for some reason we don't have a user that was passed in then we need to exit
  if (!user) {
    return;
  }

  // Get a reference to the place in the database where a user profile might be.
  const userRef = firestore.doc(`users/${user.uid}`);

  // Go and fetch the document from that location.
  const snapshot = await userRef.get();

  // The user does not exist. It is time to create them
  if (!snapshot.exists) {
    const { displayName, email, photoURL } = user;
    const createdAt = new Date();
    try {
      const team = await ensureTeamForUser(user.uid, user.email, createdAt);
      await userRef.set({
        displayName,
        email,
        photoURL,
        createdAt,
        team,
        ...additionalData
      });
      // Add some sample questions for new user. Intentionally, fire and forget. No need to wait.
      // saveDefaultQuestions(team, user.uid);  // TODO: Multilingual support?
    } catch (error) {
      console.error('Error creating user', error.message);
    }
  }

  return getUserDocument(user.uid);
};

const ensureTeamForUser = async (uid: any, email: any, createdAt: Date) => {
  const inviteRef = firestore.doc(`invites/${email}`);
  const inviteSnapshot = await inviteRef.get();

  if (inviteSnapshot.exists) {
    return inviteSnapshot.get('team');
  }

  // User doesn't exist yet and you haven't been invited. You get your own team.
  const teamRef = firestore.doc(`teams/${uid}`);
  // TODO: Handle error
  await teamRef.set({ displayName: '', createdAt });
  return uid;
};

export const getUserDocument = async (uid: any) => {
  if (!uid) return null;
  try {
    return firestore.collection('users').doc(uid);
  } catch (error) {
    console.error('Error fetching user', error.message);
  }
};

export const getTeamMembers = async (teamId: string) => {
  const membersRef = firestore
    .collection(`users`)
    .where('team', '==', teamId)
    .orderBy('displayName', 'asc');
  const membersQuerySnapshot = await membersRef.get();
  const members = membersQuerySnapshot.docs.map(queryDocumentSnapshotToMember);
  return members;
};

export const getTeamMemberQuery = (teamId: string) => {
  return firestore.collection(`users`).where('team', '==', teamId);
};

export const getInviteQuery = (teamId: string) => {
  return firestore.collection(`invites`).where('team', '==', teamId);
};

export const hasInvite = async (inviteId: string) => {
  const snap = await firestore.doc(`invites/${inviteId}`).get();
  return snap.exists;
};

export const removeInvite = async (inviteId: string) => {
  await firestore.doc(`invites/${inviteId}`).delete();
};

export const addInvite = async (teamId: string, email: string, subject: string, message: string) => {
  const inviteRef = firestore.doc(`invites/${email}`);
  const doc = await inviteRef.get();

  if (doc.exists) {
    return false;
  }

  // Double checking here, in case the person was never invited but signed themselves up
  const providers = await auth.fetchSignInMethodsForEmail(email);
  if (providers.length > 0) {
    return false;
  }

  await inviteRef.set({ team: teamId, createdAt: new Date() });

  // Consider adding a transaction & await
  firestore.collection('mail').add({
    to: email,
    message: {
      subject: subject,
      html: message
    }
  });
  return true;
};

export const updateUserWithPhoto = async (userId: string, profile: any) => {
  const userRef = firestore.doc(`users/${userId}`);
  await userRef.update({
    displayName: profile.displayName,
    language: profile.language
  });

  // TODO: Limit size... resize...
  // https://codepen.io/tuanitpro/pen/wJZJbp?editors=1010
  // https://www.npmjs.com/package/react-image-file-resizer
  if (profile.photoName) {
    const ext = profile.photoName.substr(profile.photoName.lastIndexOf('.') + 1);
    storage
      .ref()
      .child('user-profiles')
      .child(userId)
      .child('profile.' + ext)
      .put(profile.photoTemp)
      .then(response => response.ref.getDownloadURL())
      .then(photoURL => userRef.update({ photoURL }));
  }
};

export const updateUserTourTaken = async (userId: string) => {
  await firestore.doc(`users/${userId}`).update({ isTourTaken: true });
};

//#endregion

//#region Question Methods
export const getQuestion = async (teamId: string, questionId: string) => {
  if (questionId === '0') {
    return {
      id: null,
      name: '',
      private: false,
      tags: [],
      templates: [],
      question: '',
      answer: '',
      owner: '',
      published: true
    };
  }
  const docRef = firestore.collection(`teams/${teamId}/questions`).doc(questionId);
  const doc = await docRef.get();
  const question = documentSnapshotToQuestion(doc);
  return question;
};

export const getQuestionsQuerySorted = (teamId: string) => {
  return firestore.collection(`teams/${teamId}/questions`).orderBy('name', 'asc');
};

export const addQuestion = async (teamId: string, name: string) => {
  const newQuestion: QuestionProps = {
    id: '',
    name: name,
    private: false,
    tags: [],
    templates: [],
    question: '',
    answer: '',
    owner: '',
    published: true
  };

  const newQuestionRef = await firestore.collection(`teams/${teamId}/questions`).add(newQuestion);
  return newQuestionRef.id;
};

export const saveQuestion = async (teamId: string, question: any) => {
  if (question) {
    question.createdBy = auth.currentUser.uid;
  }

  if (!question.id) {
    const newQuestionRef = await firestore.collection(`teams/${teamId}/questions`).add(question);
    question.id = newQuestionRef.id;
  }
  await firestore
    .collection(`teams/${teamId}/questions`)
    .doc(question.id)
    .set(question);

    return question.id;
};

export const saveDefaultQuestions = async (teamId: string, userId: string) => {
  // TODO: Multilingual support?
  await Promise.all([
    saveQuestion(teamId, {
      name: 'Greatest Strength',
      question: 'What is your greatest strength?',
      answer: 'Does the candidates response align with the companies needs?',
      tags: ['Behavioral'],
      owner: userId,
      private: false,
      published: true
    }),
    saveQuestion(teamId, {
      name: 'Greatest Weakness',
      question: 'What is your greatest weakness?',
      answer: 'Can they turn their weakness into a positive?',
      tags: ['Behavioral'],
      owner: userId,
      private: false,
      published: true
    })
  ]);
};

export const removeQuestion = async (questionId: string, teamId: string) => {
  var docSnap = await firestore
    .collection(`teams/${teamId}/questions`)
    .doc(questionId)
    .get();
  var question = await docSnap.data();
  var templates = question.templates || [];

  // TODO: Turn this into a clould function
  templates.forEach(async (t: string) => {
    await saveQuestionChangeToTemplate(t, false, teamId, question);
  });

  await firestore.doc(`teams/${teamId}/questions/${questionId}`).delete();
};

export const cloneQuestion = async (teamId: string, questionId: string) => {
  var docSnap = await firestore
    .collection(`teams/${teamId}/questions`)
    .doc(questionId)
    .get();
  var question = await docSnap.data();

  // TODO: Append " (copy)" or not?
  question.id = '';
  question.templates = [];
  

  const newQuestionRef = await firestore.collection(`teams/${teamId}/questions`).add(question);
  await newQuestionRef.update({ id: newQuestionRef.id });
  return newQuestionRef.id;

  // NOTE: Leaving, just in case. Not sure it makes 100% sense to auto add cloded question to existing templates.
  // templates.forEach(async (t: string) => {
  //   await saveQuestionChangeToTemplate(t, true, teamId, question);
  // });
};
//#endregion

//#region Question / Template / Org Methods
export const saveQuestionChangeToTemplate = async (id: string, isAdd: boolean, team: string, question: any) => {
  const path = `teams/${team}/templates/${id}/selected`;
  const documentSnapshot = await firestore
    .collection(path)
    .doc('0')
    .get();
  let questions = documentSnapshot.data() || { selected: [] };

  if (isAdd) {
    questions.selected.push({ id: question.id, name: question.name, tags: question.tags });
  } else {
    questions.selected = questions.selected.filter((q: any) => q.id !== question.id);
  }
  await firestore
    .collection(path)
    .doc('0')
    .set({ selected: questions.selected });
};

export const saveTemplateSelectedQuestions = async (teamId: string, templateId: string, selected: any) => {
  firestore
    .collection(`teams/${teamId}/templates/${templateId}/selected`)
    .doc('0')
    .set({ selected });
};

export const saveTemplateChangeToQuestion = async (
  questionId: string,
  isAdd: boolean,
  teamId: string,
  templateId: string
) => {
  const questionRef = firestore.collection(`teams/${teamId}/questions`).doc(questionId);
  const question = await questionRef.get();
  let templates = question.get('templates') || [];

  if (isAdd && !templates.includes(templateId)) {
    templates.push(templateId);
  } else if (!isAdd) {
    templates = templates.filter((item: string) => item !== templateId);
  }

  await questionRef.update({ templates: templates });
};

export const getTemplatSelectedQuestions = async (teamId: string, templateId: string) => {
  const selectedRef = firestore.collection(`teams/${teamId}/templates/${templateId}/selected/`).doc('0');
  const selectedDocumentSnapshot = await selectedRef.get();
  const data = selectedDocumentSnapshot.data();
  return data && data.selected ? data.selected : [];
};
//#endregion

//#region Template (aka Position) Methods
export const addTemplate = async (teamId: string) => {
  return await addTemplateWithName(teamId, '');
};

export const addTemplateWithName = async (teamId: string, name: string) => {
  const newTemplate: TemplateProps = { id: '', name, private: false, createdBy: auth.currentUser.uid };
  //question.createdBy = auth.currentUser.uid;
  const newTemplateRef = await firestore.collection(`teams/${teamId}/templates`).add(newTemplate);

  newTemplate.id = newTemplateRef.id;
  await firestore
    .collection(`teams/${teamId}/templates`)
    .doc(newTemplate.id)
    .set(newTemplate);

  return newTemplate.id;
};

export const getTemplate = async (teamId: string, templateId: string) => {
  const docRef = firestore.collection(`teams/${teamId}/templates`).doc(templateId);
  const doc = await docRef.get();
  const template = documentSnapshotToTemplate(doc);
  return template;
};

export const getTemplates = async (teamId: string) => {
  const templatesRef = getTemplatesQuery(teamId);
  const templatesQuerySnapshot = await templatesRef.get();
  const templates = templatesQuerySnapshot.docs.map(queryDocumentSnapshotToTemplate);
  return templates;
};

export const getTemplatesQuery = (teamId: string) => {
  return firestore.collection(`teams/${teamId}/templates`).orderBy('name', 'asc');
};

export const cloneTemplate = async (teamId: string, templateId: string) => {
  // Get Template to Clone
  const fromRef = firestore.doc(`teams/${teamId}/templates/${templateId}`);
  const fromDocSnap = await fromRef.get();
  const template = await fromDocSnap.data();

  // Get Selected Questions
  const selected = await getTemplatSelectedQuestions(teamId, templateId);

  // Save Template
  template.id = '';
  template.name = template.name + ' (copy)'; // TODO: official name of copy? i18n?
  template.createdBy = auth.currentUser.uid;
  const newTemplateRef = await firestore.collection(`teams/${teamId}/templates`).add(template);
  newTemplateRef.update({ id: newTemplateRef.id });

  // Save Selected Questions
  await firestore
    .collection(`teams/${teamId}/templates/${newTemplateRef.id}/selected`)
    .doc('0')
    .set({ selected: selected });

  // Annotate each question with the template
  selected.forEach(async (question: any) => {
    await saveTemplateChangeToQuestion(question.id, true, teamId, newTemplateRef.id);
  });

  return newTemplateRef.id;
};

export const deleteTemplate = async (teamId: string, templateId: string) => {
  await deleteCollection(`teams/${teamId}/templates/${templateId}/selected`);
  await firestore.doc(`teams/${teamId}/templates/${templateId}`).delete();
};

export const saveTemplate = async (teamId: string, template: any) => {
  firestore
    .collection(`teams/${teamId}/templates`)
    .doc(template.id)
    .set(template);
};
//#endregion

//#region Interview Methods
export const addInterview = async (teamId: string) => {
  const newInterview: TInterview = {
    id: '',
    userId: '',
    userDisplayName: '',
    candidateName: '',
    templateId: '',
    templateName: '',
    startDate: null,
    createdDate: new Date(),
    notes: '',
    createdBy: auth.currentUser.uid
  };

  const newInterviewRef = await getInterviewQuery(teamId).add(newInterview);
  return newInterviewRef.id;
};

export const getInterview = async (teamId: string, interviewId: string) => {
  if (interviewId === '0') {
    return {
      id: '',
      userId: '',
      userDisplayName: '',
      candidateName: '',
      templateId: '',
      templateName: '',
      startDate: null,
      createdDate: new Date(),
      createdBy: auth.currentUser.uid,
      notes: ''
    };
  }
  const interviewRef = firestore.collection(`teams/${teamId}/interviews`).doc(interviewId);
  const interviewDocumentSnapshot = await interviewRef.get();
  const interview = documentSnapshotToInterview(interviewDocumentSnapshot);
  return interview;
};

export const getInterviewQuery = (teamId: string) => {
  return firestore.collection(`teams/${teamId}/interviews`);
};

export const getInterviewQueryWithLimit = (teamId: string, limit: number) => {
  return firestore
    .collection(`teams/${teamId}/interviews`)
    .orderBy('createdDate', 'desc')
    .limit(limit);
};

export const getInterviewsWithLimit = async (teamId: string, limit: number) => {
  const query = firestore.collection(`teams/${teamId}/interviews`).limit(limit);
  const querySnapshot = await query.get();
  const interviews = querySnapshot.docs.map(queryDocumentSnapshotToInterview);
  return interviews;
};

export const deleteInterview = async (teamId: string, interviewId: string) => {
  await deleteCollection(`teams/${teamId}/interviews/${interviewId}/interviewQuestions`);
  await firestore.doc(`teams/${teamId}/interviews/${interviewId}`).delete();
};

export const saveInterview = async (teamId: string, interview: any) => {
  if (!interview.id) {
    interview.createdBy = auth.currentUser.uid
    const newInterviewRef = await firestore.collection(`teams/${teamId}/interviews`).add(interview);
    interview.id = newInterviewRef.id;
  }
  
  // Need to make sure something gets passed in. Undefined will kill it
  if (!interview.createdBy) {
    interview.createdBy = null;
  }

  await firestore
    .collection(`teams/${teamId}/interviews`)
    .doc(interview.id)
    .set(interview);

  return interview.id;
};

export const updateInterview = async (teamId: string, interview: any) => {
  firestore
    .collection(`teams/${teamId}/interviews`)
    .doc(interview.id)
    .update({
      notes: interview.notes,
      startDate: interview.startDate ? interview.startDate : new Date()
    });
};
//#endregion

//#region Interview / Questions
export const updateInterviewQuestion = async (
  teamId: string,
  interviewId: string,
  questionId: string,
  candidateResponse: string,
  candidateRating: number
) => {
  await firestore
    .collection(`teams/${teamId}/interviews/${interviewId}/interviewQuestions`)
    .doc(questionId)
    .update({
      candidateResponse: candidateResponse || null,
      candidateRating: candidateRating || null
    });
};

export const initInterviewQuestions = async (teamId: string, interview: any) => {
  // If the interview hasn't started, might as well get the latest. Specifically template might change
  if (!interview.startDate) {
    await deleteCollection(`teams/${teamId}/interviews/${interview.id}/interviewQuestions`);
    const interviewQuestionsRef = firestore.collection(`teams/${teamId}/interviews/${interview.id}/interviewQuestions`);

    const questionsRef = firestore
      .collection(`teams/${teamId}/questions`)
      .where('templates', 'array-contains', interview.templateId);
    const questionsQuerySnapshot = await questionsRef.get();
    const questionPositions = await getTemplatSelectedQuestions(teamId, interview.templateId);
    questionsQuerySnapshot.docs.forEach(async x => {
      let data = x.data();
      data.position = questionPositions.findIndex((o: any) => o.id === x.id);
      await interviewQuestionsRef.doc(x.id).set(data);
    });
  }

  const interviewQuestions = await firestore
    .collection(`teams/${teamId}/interviews/${interview.id}/interviewQuestions`)
    .orderBy('position', 'asc')
    .get();
  const questions = interviewQuestions.docs.map(queryDocumentSnapshotToInterviewQuestion);
  return questions;
};

export const initInterviewQuestionsRemote = async (teamId: string, interview: any) => {
  var ensureInterviewQuestions = functions.httpsCallable('ensureInterviewQuestions');
  const result = await ensureInterviewQuestions({ teamId, interview });
  return result.data;
};

//#endregion

//#region Tags
export const getTagsByCount = async (teamId: string) => {
  const tagsRef = firestore.collection(`teams/${teamId}/tags`).orderBy('count', 'asc');
  const tagsQuerySnapshot = await tagsRef.get();
  const tags = tagsQuerySnapshot.docs.map((docSnap: any) => {
    return docSnap.id;
  });
  return tags;
};

export const resetTags = async (teamId: string) => {
  var resetTags = functions.httpsCallable('resetTags');
  const result = await resetTags({ teamId });
  return result.data;
};
//#endregion

//#region Stripe
export interface IProductPrices {
  id: string;
  active: boolean;
  name: string;
  monthlyPriceId?: string;
  monthlyPriceAmount?: number;
  yearlyPriceId?: string;
  yearlyPriceAmount?: number;
}

export interface ISubscription {
  id: string;
  cancel_at?: Date;
  current_period_end?: Date;
  role?: string;
  status?: string;
  productName?: string;
  interval?: string;
  currency?: string;
  unit_amount?: number;
  quantity?: number;
}

export const getCustomClaimRole = async () => {
  await auth.currentUser.getIdToken(true);
  const decodedToken = await auth.currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
};

export const ensureStripeCustomer = async (userId: string, email: string) => {
  const customerRef = firestore.doc(`customers/${userId}`);
  const snapshot = await customerRef.get();

  if (!snapshot.exists) {
    await createStripeCustomer(userId, email);
  }
};

export const createStripeCustomer = async (userId: string, email: string) => {
  const func = functions.httpsCallable('createCustomerRecord');
  await func({ email, uid: userId });
};

export const getFirstProduct = async (): Promise<IProductPrices> => {
  const productsQuerySnapshot = await firestore.collection(`products`).get();

  if (productsQuerySnapshot.empty) {
    return null;
  }

  const doc = productsQuerySnapshot.docs[0];
  const data = doc.data();

  return { id: doc.id, active: data.active, name: data.name };
};

export const getProductsAndPrices = async (): Promise<IProductPrices> => {
  const product = await getFirstProduct();
  if (!product) {
    return null;
  }

  const pricesQuerySnapshot = await firestore.collection(`products/${product.id}/prices`).get();

  if (pricesQuerySnapshot.empty) {
    return null;
  }

  pricesQuerySnapshot.forEach(doc => {
    const data = doc.data();
    if (data.active) {
      if (data.interval === 'month') {
        product.monthlyPriceId = doc.id;
        product.monthlyPriceAmount = data.unit_amount;
      } else if (data.interval === 'year') {
        product.yearlyPriceId = doc.id;
        product.yearlyPriceAmount = data.unit_amount;
      }
    }
  });

  return product;
};

export const redirectToStripeCheckout = async (
  userId: string,
  priceId: string,
  quantity: string,
  rates: Array<string>,
  success: Function,
  failure: Function
) => {
  // TODO: convert to Promise.all to save time with the loadStrip call
  const docRef = await firestore
    .collection('customers')
    .doc(userId)
    .collection('checkout_sessions')
    .add({
      line_items: [
        {
          price: priceId,
          quantity: quantity,
          dynamic_tax_rates: rates
        }
      ],
      success_url: window.location.origin + '/upgradeSuccess',
      cancel_url: window.location.origin + '/upgrade?q=' + quantity
    });

  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot(async snap => {
    const { error, sessionId } = snap.data();

    if (error) {
      failure(error);
    }

    if (sessionId) {
      try {
        // We have a session, let's redirect to Checkout
        const stripe = await loadStripe(stripePK);
        // TODO: It seems that Stripe wants locale to be passed as part of server/session creation.
        // However, the extension that is being used does not provide a param for this (AFAIK).
        // const locale: CheckoutLocale = language as CheckoutLocale;
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
          failure(error.message);
        } else {
          success();
        }
      } catch (error) {
        if (error && error.message) {
          failure(error.message);
        }
      }
    }
  });
};

export const getTaxRates = async () => {
  const func = functions.httpsCallable('getTaxRates');
  const taxRates = await func();
  return taxRates.data.data.map((r: any) => {
    return r.id;
  });
};

export const getSubscriptionData = async (userId: string): Promise<ISubscription> => {
  const snap = await firestore
    .collection('customers')
    .doc(userId)
    .collection('subscriptions')
    .orderBy('created', 'desc')
    .limit(1)
    .get();

  if (snap.empty) {
    return { id: 'demo' };
  } else {
    const data = snap.docs[0].data();

    const subscriptionData: ISubscription = {
      id: snap.docs[0].id,
      cancel_at: data.cancel_at ? data.cancel_at.toDate() : null,
      current_period_end: data.current_period_end ? data.current_period_end.toDate() : null,
      role: data.role,
      status: data.status,
      quantity: data.quantity
    };

    const priceDocSnap = await data.price.get();
    const parentDocSnap = await data.price.parent.parent.get();

    if (priceDocSnap.exists && parentDocSnap.exists) {
      const priceData = priceDocSnap.data();
      const productData = parentDocSnap.data();
      subscriptionData.unit_amount = priceData.unit_amount;
      subscriptionData.currency = priceData.currency;
      subscriptionData.interval = priceData.interval;
      subscriptionData.productName = productData.name;
    }

    return subscriptionData;
  }
};

export const isUserCurrentlySubscribed = async (userId: string): Promise<boolean> => {
  const snap = await firestore
    .collection('customers')
    .doc(userId)
    .collection('subscriptions')
    .orderBy('created', 'desc')
    .limit(1)
    .get();

  if (snap.empty) {
    return false;
  }

  const data = snap.docs[0].data();

  // TODO: consider if I want to handle 3 states. active | canceling | canceled
  // If so, a TypeScript enum might be better: https://www.typescriptlang.org/docs/handbook/enums.html#string-enums
  return data.status === 'active';
};

export const getTeamClaims = async (team: string) => {
  const func = functions.httpsCallable('getTeamClaims');
  const result = await func({ team });
  return result?.data?.stripeRole;
};

export const getInactiveTeamMembers = async (team: string ): Promise<string[]> => {
  const usersInactiveRef = firestore.doc(`usersInactive/${team}`);
  const snapshot = await usersInactiveRef.get();

  if (!snapshot.exists) {
    return [];
  }

  return snapshot.get('users');
};

export const addInactiveTeamMember = async (team: string, id: string) => {
  const ref = firestore.collection('usersInactive').doc(team);
  const snapshot = await ref.get();

  if (snapshot.exists) {
    ref.update({ users: firebase.firestore.FieldValue.arrayUnion(id) });
  } else {
    // create the document
    ref.set({ users: [id] });
  }
};

export const removeInactiveTeamMember = async (team: string, id: string) => {
  const ref = firestore.collection('usersInactive').doc(team);
  const snapshot = await ref.get();

  if (snapshot.exists) {
    ref.update({ users: firebase.firestore.FieldValue.arrayRemove(id) });
  } else {
    // create the document
    ref.set({ users: [] });
  }
};

export const isInactive = async (team: string, id: string): Promise<boolean> => {
  const ref = firestore.collection('usersInactive').doc(team);
  const snapshot = await ref.get();

  if (!snapshot.exists) {
    return false;
  }

  const users = snapshot.get('users') as string[];

  if (!users) {
    return false;
  }

  return users.some(u => u === id);
};

export const redirectToStripePortal = async (returnUrl?: string) => {
  const createPortalLink = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
  const relativeUrl = (returnUrl) ? returnUrl : '/profile';
  const { data } = await createPortalLink({ returnUrl: window.location.origin + relativeUrl });
  window.location.assign(data.url);
};

export const createTaxRates = async () => {
  await createTaxRate('AL', 4.0, false, 'Alabama');
  await createTaxRate('AK', 0.0, true, 'Alaska');
  // await createTaxRate('AZ', 5.6, true, 'Arizona');
  await createTaxRate('AR', 6.5, false, 'Arkansas');
  await createTaxRate('CA', 7.25, false, 'California');
  await createTaxRate('CO', 2.9, false, 'Colorado');
  await createTaxRate('CT', 6.35, true, 'Connecticut');
  await createTaxRate('DE', 0.0, false, 'Delaware');
  await createTaxRate('FL', 6.0, false, 'Florida');
  await createTaxRate('GA', 4.0, false, 'Georgia');
  await createTaxRate('HI', 4.0, true, 'Hawaii');
  await createTaxRate('ID', 6.0, false, 'Idaho');
  await createTaxRate('IL', 6.25, false, 'Illinois');
  await createTaxRate('IN', 7.0, false, 'Indiana');
  await createTaxRate('IA', 6.0, true, 'Iowa');
  await createTaxRate('KS', 6.5, false, 'Kansas');
  await createTaxRate('KY', 6.0, false, 'Kentucky');
  await createTaxRate('LA', 4.45, false, 'Louisiana'); // SaaS for business use is considered non-taxable
  await createTaxRate('ME', 5.5, true, 'Maine');
  await createTaxRate('MD', 6.0, false, 'Maryland'); // SaaS for business use is considered non-taxable
  await createTaxRate('MA', 6.25, false, 'Massachusetts'); // SaaS for business use is considered non-taxable
  await createTaxRate('MI', 6.0, false, 'Michigan');
  await createTaxRate('MN', 6.875, false, 'Minnesota');
  await createTaxRate('MS', 7.0, true, 'Mississippi');
  await createTaxRate('MO', 4.225, false, 'Missouri');
  await createTaxRate('MT', 0.0, false, 'Montana');
  await createTaxRate('NE', 5.5, false, 'Nebraska');
  await createTaxRate('NV', 6.85, true, 'Nevada'); // Taxable for business use
  await createTaxRate('NH', 0.0, false, 'New Hampshire'); //?????
  await createTaxRate('NJ', 6.625, false, 'New Jersey');
  await createTaxRate('NM', 5.125, true, 'New Mexico');
  await createTaxRate('NY', 4.0, true, 'New York');
  await createTaxRate('NC', 4.75, false, 'North Carolina');
  await createTaxRate('ND', 5.0, true, 'North Dakota'); // Taxable for business use
  await createTaxRate('OH', 5.75, true, 'Ohio'); // Taxable for business use
  await createTaxRate('OK', 4.5, false, 'Oklahoma');
  await createTaxRate('OR', 0.0, false, 'Oregon');
  await createTaxRate('PA', 6.0, true, 'Pennsylvania');
  await createTaxRate('RI', 7.0, true, 'Rhode Island');
  await createTaxRate('SC', 6.0, true, 'South Carolina');
  await createTaxRate('SD', 4.5, true, 'South Dakota');
  await createTaxRate('TN', 7.0, true, 'Tennessee');
  await createTaxRate('TX', 6.25, true, 'Texas');
  await createTaxRate('UT', 4.85, true, 'Utah');
  await createTaxRate('VT', 6.0, false, 'Vermont');
  await createTaxRate('VA', 4.3, false, 'Virginia');
  await createTaxRate('WA', 6.5, true, 'Washington');
  await createTaxRate('WV', 6.0, true, 'West Virginia');
  await createTaxRate('WI', 5.0, false, 'Wisconsin');
  await createTaxRate('WY', 4.0, false, 'Wyoming');
  //https://blog.taxjar.com/where-do-saas-companies-collect-sales-tax/
};

export const createTaxRate = async (abv: string, rate: number, taxable: boolean, desc: string) => {
  const func = functions.httpsCallable('createTaxRate');
  await func({
    country: 'US',
    state: abv,
    inclusive: true,
    percentage: taxable ? rate : 0.0,
    jurisdiction: `US - ${abv}`,
    description: `US - ${desc} (${abv})`,
    display_name: `US - ${abv}`
  });
};
//#endregion

// NOTE: This was used to test that the app does not have permissions to do this.
// export const addUserClaim = async (uid: string) => {
//   await firestore.doc(`user-claims/${uid}`).update({ isAwesome: true });
// };

export default firebase;
