import React from 'react';
import { useTranslation } from 'react-i18next';
import { TInterviewQuestion } from '../types';
import { precisionRound } from '../utilities';

const InterviewSums = (props: { questions?: TInterviewQuestion[] }) => {
  const { questions } = props;
  const { t } = useTranslation('translation');

  let sum = 0;
  let answered = 0;
  let rated = 0;

  for (var i = 0; i < questions.length; i++) {
    if (questions[i].candidateRating) {
      sum += questions[i].candidateRating;
      rated += 1;
    }

    if (questions[i].candidateResponse) {
      answered += 1;
    }
  }

  var avg = sum / rated;
  console.log('Render InterviewSums');

  return (
    <>
      <span className="has-text-weight-semibold" style={{ marginRight: '10px' }}>
        {t('InterviewInProgress.Answered')} {answered}
      </span>
      <span className="has-text-weight-semibold" style={{ marginRight: '10px' }}>
        {t('InterviewInProgress.Rated')} {rated}
      </span>
      <span className="has-text-weight-semibold">
        {t('InterviewInProgress.Average')} {precisionRound((avg ? avg : 0.0), 1)}
      </span>
    </>
  );
};

export default InterviewSums;
