import React, { useState, useEffect } from 'react';
import { getTemplatesQuery } from '../firebase';
import { getUserTeam } from '../stores/user/selectors';
import { useSelector } from 'react-redux';

const TemplatesContext = React.createContext([{}, () => {}]);

const TemplatesProvider = props => {
  const [templates, setTemplates] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const userTeam = useSelector(getUserTeam);

  useEffect(() => {
    if (!userTeam) {
      return;
    }

    // Note: that there is still knowledge of firebase here: snapshot, doc.id, doc.data()
    const unsubscribe = getTemplatesQuery(userTeam).onSnapshot(
      snapshot => {
        const templates = [];
        snapshot.forEach(doc => {
          templates.push({
            id: doc.id,
            name: doc.data().name
          });
        });
        setLoading(false);
        setTemplates(templates);
      },
      err => {
        setError(err);
      }
    );

    return unsubscribe;
  }, [userTeam]);

  return <TemplatesContext.Provider value={{ templates, loading, error }}>{props.children}</TemplatesContext.Provider>;
};

export { TemplatesContext, TemplatesProvider };
