import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getProductsAndPrices, redirectToStripeCheckout, ensureStripeCustomer, getTaxRates, isUserCurrentlySubscribed, redirectToStripePortal } from '../firebase';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import Notification from '../components/Notification';
import { APP_PARAMS, getValueFromQueryString, setDemoDismissalDate } from '../utilities';

const UpgradePage = () => {
  const user = useSelector(getUser);
  const history = useHistory();
  const [product, setProduct] = useState<any>();
  const [taxRates, setTaxRates] = useState<any>();
  const [userQty, setUserQty] = useState<any>(getValueFromQueryString('q') || "1");
  const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const [redirectError, setRedirectError] = useState('');
  const { t } = useTranslation('translation');

  useEffect(() => {
    if (!user || !user.team) {
      return;
    }

    if (loading && !user.isTeamMember) {
      // Intentionally firing and forgetting
      ensureStripeCustomer(user.uid, user.email);
    }

    getUpgradeDetailsLocal();
    async function getUpgradeDetailsLocal() {
      // Setup the queries
      let [product, rates, activeSubscription] = await Promise.all([
        getProductsAndPrices(),
        getTaxRates(),
        isUserCurrentlySubscribed(user.uid)
      ]);

      // Set State
      setProduct(product);
      setTaxRates(rates);
      setHasActiveSubscription(activeSubscription);
      setLoading(false);
    }
  }, [user, loading]);

  const handleUpgrade = async (priceId: string) => {
    setRedirecting(true);

    redirectToStripeCheckout(
      user.uid,
      priceId,
      userQty,
      taxRates,
      () => {
        // Success condition
        setRedirecting(false);
      },
      (error: string) => {
        // Error condition
        setRedirectError(error);
        setRedirecting(false);
      }
    );
  };

  const handleUserQtyChange = ({ target }: any) => {
    let value = target.value;
    setUserQty(value);
  };

  const handleDismiss = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setDemoDismissalDate(user.email);
    history.push('/questions');
  };

  const handleEditSubscription = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setRedirecting(true);
    await redirectToStripePortal('/profile');
  };

  if (loading) {
    return <div>{t('General.Loading')}</div>;
  }

  if (!loading && user.isTeamMember) {
    return <div>{t('Upgrade.MemberWarning')}</div>;
  }

  if (!loading && !redirecting && !user.isTeamMember && hasActiveSubscription) {
    return (
      <Notification>
        {t('Upgrade.ExistingSubscription')}{' '}
        <a onClick={handleEditSubscription} href="#test">
          {t('Upgrade.EditSubscription')}
        </a>
      </Notification>
    );
  }

  if (!loading && !product) {
    return <Notification value={t('Upgrade.ProductNotFoundError')} />;
  }

  if (redirecting) {
    return (
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="box">
                <h4 className="title is-4 has-text-centered">{t('Upgrade.ConnectingToStripe')}</h4>
                <h5 className="title is-5 has-text-centered">{t('Upgrade.ThanksForPatience')}</h5>
                <div className="has-text-centered">
                  <progress className="progress is-small is-primary" max="100">
                    15%
                  </progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (redirectError) {
    return (
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="box">
                <h4 className="title is-4 has-text-centered">{t('Upgrade.ConnectingToStripeError')}</h4>
                <div className="has-text-centered">{t('Upgrade.ConnectingToStripeErrorMessage')}</div>
                <br />
                <br />
                <div className="notification is-danger is-light">
                  {t('Upgrade.ErrorLabel')} {redirectError}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section>
      <div className="level signin">
        <div className="level-item">
          <div className="pricing-table">
            <div className="pricing-plan">
              <div className="plan-header">{t('Upgrade.PlanFreeTitle')}</div>
              <div className="plan-price">
                <span className="plan-price-amount">
                  <span className="plan-price-currency">$</span>0
                </span>
                {t('Upgrade.PerMonth')}
              </div>
              <div className="plan-items">
                <div className="plan-item">
                  {APP_PARAMS.demoQuestionsLimit} {t('Upgrade.QuestionsAmount')}
                </div>
                <div className="plan-item">{t('Upgrade.PositionsAmount')}</div>
                <div className="plan-item">
                  {APP_PARAMS.demoInterviewsLimit} {t('Upgrade.InterviewsAmount')}
                </div>
              </div>
              <div className="plan-footer">
                <button className="button is-fullwidth" onClick={handleDismiss}>
                  {t('Upgrade.SelectFreePlan')}
                </button>
              </div>
            </div>

            <div className="pricing-plan is-warning">
              <div className="plan-header">{t('Upgrade.PlanBasicMonthlyTitle')}</div>
              <div className="plan-price">
                <span className="plan-price-amount">
                  <span className="plan-price-currency">$</span>
                  {product.monthlyPriceAmount / 100}
                </span>
                {t('Upgrade.PerMonth')}
              </div>
              <div className="plan-items">
                <div className="plan-item">100 {t('Upgrade.QuestionsAmount')}</div>
                <div className="plan-item">{t('Upgrade.PositionsAmount')}</div>
                <div className="plan-item">{t('Upgrade.InterviewsAmountUnlimited')}</div>
              </div>
              <div className="plan-footer">
                <div style={{ marginBottom: '10px'}}>
                  <div>{t('Upgrade.UsersQuestion')}</div>
                  <div className="control select is-fullwidth">
                    <select name="monthlyUserQty" value={userQty} onChange={handleUserQtyChange} className="is-fullwidth">
                      <option key="1" value="1">
                        1
                      </option>
                      <option key="2" value="2">
                        2
                      </option>
                      <option key="3" value="3">
                        3
                      </option>
                      <option key="4" value="4">
                        4
                      </option>
                      <option key="5" value="5">
                        5
                      </option>
                      <option key="6" value="6">
                        6
                      </option>
                      <option key="7" value="7">
                        7
                      </option>
                      <option key="8" value="8">
                        8
                      </option>
                      <option key="9" value="9">
                        9
                      </option>
                      <option key="10" value="10">
                        10
                      </option>
                    </select>
                  </div>
                  <div>{t('Upgrade.TotalLabel')} ${(product.monthlyPriceAmount / 100) * userQty}</div>
                </div>
                <button
                  className="button is-fullwidth"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();
                    handleUpgrade(product.monthlyPriceId);
                  }}
                >
                  {t('Upgrade.SelectMonthly')}
                </button>
              </div>
            </div>

            <div className="pricing-plan is-active">
              <div className="plan-header">{t('Upgrade.PlanBasicYearlyTitle')}</div>
              <div className="plan-price">
                <span className="plan-price-amount">
                  <span className="plan-price-currency">$</span>
                  {product.yearlyPriceAmount / 100}
                </span>
                {t('Upgrade.PerYear')}
              </div>
              <div className="plan-items">
                <div className="plan-item">100 {t('Upgrade.QuestionsAmount')}</div>
                <div className="plan-item">{t('Upgrade.PositionsAmount')}</div>
                <div className="plan-item">{t('Upgrade.InterviewsAmountUnlimited')}</div>
              </div>
              <div className="plan-footer">
              <div style={{ marginBottom: '10px'}}>
                  <div>{t('Upgrade.UsersQuestion')}</div>
                  <div className="control select is-fullwidth">
                    <select name="yearlyUserQty" value={userQty} onChange={handleUserQtyChange} className="is-fullwidth">
                      <option key="1" value="1">
                        1
                      </option>
                      <option key="2" value="2">
                        2
                      </option>
                      <option key="3" value="3">
                        3
                      </option>
                      <option key="4" value="4">
                        4
                      </option>
                      <option key="5" value="5">
                        5
                      </option>
                      <option key="6" value="6">
                        6
                      </option>
                      <option key="7" value="7">
                        7
                      </option>
                      <option key="8" value="8">
                        8
                      </option>
                      <option key="9" value="9">
                        9
                      </option>
                      <option key="10" value="10">
                        10
                      </option>
                    </select>
                  </div>
                  <div>{t('Upgrade.TotalLabel')} ${(product.yearlyPriceAmount / 100) * userQty}</div>
                </div>
                <button
                  className="button is-fullwidth"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();
                    handleUpgrade(product.yearlyPriceId);
                  }}
                >
                  {t('Upgrade.SelectYearly')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpgradePage;
