import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const StarNumber = (props: { value?: number }) => {
  const { value } = props;

  return (
    <span className="fa-layers fa-fw">
      <Icon icon={faStar} size="2x" style={{ color: !value || value < 1 ? 'lightgrey' : '#ffd700' }} />
      <span
        className="fa-stack-1x"
        style={{ color: 'black', paddingLeft: value && value > 9 ? '8px' : '13px', top: '-2px' }}
      >
        {value}
      </span>
    </span>
  );
};

export default StarNumber;
