import React, { useState, useEffect } from 'react';
import { getQuestionsQuerySorted } from '../firebase';
import { getUserTeam } from '../stores/user/selectors';
import { useSelector } from 'react-redux';

const QuestionsContext = React.createContext([{}, () => {}]);

const QuestionsProvider = props => {
  const [questions, setQuestions] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const userTeam = useSelector(getUserTeam);

  useEffect(() => {
    if (!userTeam) {
      return;
    }

    // Note: that there is still knowledge of firebase here: snapshot, doc.id, doc.data()
    const unsubscribe = getQuestionsQuerySorted(userTeam).onSnapshot(
      snapshot => {
        const questions = [];
        snapshot.forEach(doc => {
          questions.push({
            id: doc.id,
            name: doc.data().name,
            tags: doc.data().tags
          });
        });
        setLoading(false);
        setQuestions(questions);
      },
      err => {
        setError(err);
      }
    );

    return unsubscribe;
  }, [userTeam]);

  return <QuestionsContext.Provider value={{ questions, loading, error }}>{props.children}</QuestionsContext.Provider>;
};

export { QuestionsContext, QuestionsProvider };
