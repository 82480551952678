import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getValueFromQueryString } from '../utilities';

const PublicLanguageChanger = () => {
  const { i18n, t } = useTranslation('translation');
  const history = useHistory();

  const languages = Object.keys(i18n.options.resources);

  const handleLangChange = ({ target }: any) => {
    let value = target.value;

    const email = getValueFromQueryString('email');
    let emailQueryString = ''

    if (email) {
      emailQueryString = `&email=${email}`;
    }

    history.push({ search: `lang=${value}${emailQueryString}` });
    i18n.changeLanguage(value);
  };

  return (
    <div className="level signin-lang">
      <div className="level-item">
        <div className="card">
          <div className="card-content">
            <select name="language" value={i18n.language} onChange={handleLangChange}>
              {languages.map((l: any) => (
                <option key={l} value={l}>
                  {t(`Languages.${l}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicLanguageChanger;
