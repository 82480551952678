import React, { useEffect, useState } from 'react';
import {
  addInvite,
  removeInvite,
  getInviteQuery,
  getTeamMemberQuery,
  getSubscriptionData,
  ISubscription,
  redirectToStripePortal,
  getInactiveTeamMembers,
  addInactiveTeamMember,
  removeInactiveTeamMember
} from '../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';
import { queryDocumentSnapshotToInvite, queryDocumentSnapshotToMember } from '../utilities';
import ProfileCard from '../components/ProfileCard';
import { useSelector } from 'react-redux';
import { getUser, getUserTeam } from '../stores/user/selectors';
import { InviteProps, MemberProps } from '../types';
import Notification from '../components/Notification';
import { Link } from 'react-router-dom';

const TeamsPage = () => {
  const userTeam = useSelector(getUserTeam);
  const user = useSelector(getUser);
  const { t, i18n } = useTranslation('translation');
  const [value, loading, error] = useCollection(getInviteQuery(userTeam));
  const [valueMember] = useCollection(getTeamMemberQuery(userTeam));
  const [subscription, setSubscription] = useState<ISubscription>();
  const [inactiveTeamMembers, setInactiveTeamMembers] = useState([])
  const [redirecting, setRedirecting] = useState(false);
  const [addInviteFailed, setAddInviteFailed] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!user || !user.team) {
      return;
    }

    getDataLocal();
    async function getDataLocal() {
      const teamId = user.isTeamMember ? user.team : user.uid;
      const localSubscription = await getSubscriptionData(teamId);
      const localInactiveTeamMembers = await getInactiveTeamMembers(teamId);
      setSubscription(localSubscription);
      setInactiveTeamMembers(localInactiveTeamMembers);
    }
  }, [user]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleEditSubscription = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setRedirecting(true);
    await redirectToStripePortal('/team');
  };

  const removeInviteHandler = async (id: string) => {
    await removeInvite(id);
  };

  const activateMemberHandler = async (id: string) => {
    await removeInactiveTeamMember(user.team, id);
    const localInactiveTeamMembers = await getInactiveTeamMembers(user.team);
    setInactiveTeamMembers(localInactiveTeamMembers);
  };

  const inactivateMemberHandler = async (id: string) => {
    await addInactiveTeamMember(user.team, id);
    const localInactiveTeamMembers = await getInactiveTeamMembers(user.team);
    setInactiveTeamMembers(localInactiveTeamMembers);
  };

  const addInviteHandler = async () => {
    const subject = t('Team.InviteSubject');
    const message = t('Team.InviteMessage', {
      domain: window.location.origin,
      displayName: user.displayName,
      email,
      lang: i18n.language
    });
    setAddInviteFailed(!(await addInvite(userTeam, email, subject, message)));
    setEmail('');
  };

  function renderInviteForm(membersCount: number, inactiveMembersCount: number, invitesCount: number) {
    // stripeRole == basic && subscription.id == demo
    // Only happens if they have been manually granted.
    // In this case we should send them to upgrade page
    // TODO: It would make more sense to set the .id to 'none'
    if (subscription.id === 'demo') {
      return (
        <Notification>
          <div>{t('Team.UpgradeMessage')}</div>
          <Link to="/upgrade" className="button is-success">
            {t('General.UpgradeNow')}
          </Link>
        </Notification>
      );
    } else if (subscription.cancel_at) {
      return (
        <Notification>
          <div>{t('Team.CanceledMembershipMessage')}</div>
          <a onClick={handleEditSubscription} href="#test">
            {t('Profile.RenewSubscription')}
          </a>
        </Notification>
      );
    } else if (subscription.quantity > (membersCount - inactiveMembersCount) + invitesCount) {
      return (
        <>
          {!addInviteFailed ? null : (
            <div className="field">
              <Notification isError={true}>
                {t('Team.InviteEmailAddressInUseMessage')}
              </Notification>
            </div>
          )}

          <div className="columns">
            <div className="column is-three-quarters">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="email"
                    placeholder={t('Team.EmailPlaceholder')}
                    onChange={handleInputChange}
                  />
                </p>
              </div>
              <div className="field">
                <p className="control">
                  {t('Team.TotalsSubscriptions')} {subscription.quantity}&nbsp;&nbsp;
                  {t('Team.TotalsInvites')} {invitesCount}&nbsp;&nbsp; 
                  {t('Team.TotalsActiveMembers')} {membersCount - inactiveMembersCount}&nbsp;&nbsp; 
                  {t('Team.TotalsRemaining')} {subscription.quantity - invitesCount - (membersCount - inactiveMembersCount)}
                </p>
              </div>
            </div>
            <div className="column">
              <button
                className="button is-success is-fullwidth"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  addInviteHandler();
                }}
              >
                {t('Team.SendButton')}
              </button>
            </div>
          </div>
        </>
      );
    } else if (subscription.quantity === (membersCount - inactiveMembersCount) + invitesCount) {
      return (
        <Notification>
          {t('Team.TeamFullMessage')}{' '}
          <a onClick={handleEditSubscription} href="#test">
            {t('Team.EditSubscription')}
          </a>
        </Notification>
      );
    } else if (subscription.quantity < (membersCount - inactiveMembersCount) + invitesCount) {
      return (
        <Notification isError={true}>
          {t('Team.TeamOverLimitMessage')}{' '}
          <a onClick={handleEditSubscription} href="#test">
            {t('Team.EditSubscription')}
          </a>{' '}
          {t('Team.TeamOverLimitMessage2')}
          <br />
          <br />
          {t('Team.TeamOverLimitMessage3')}
        </Notification>
      );
    }
  }

  function renderDemoUserNotice() {
    return (
      <Notification>
        <div>
          {t('Team.DemoUserMessage')}
        </div>
        <Link to="/upgrade" className="button is-success">
          {t('General.UpgradeNow')}
        </Link>
      </Notification>
    );
  }

  function renderMemberActionButton(memberId: string, inactive: string[], remaining: number) {
    if (user.isTeamMember || user.uid === memberId) {
      return null;
    }

    if (inactive.some(i => i === memberId)) {
      return (
        <button
          className="button is-success"
          disabled={remaining <= 0}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            activateMemberHandler(memberId);
          }}
        >
          {t('Team.Activate')}
        </button>
      );
    } else {
      return (
        <button
          className="button is-danger"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            inactivateMemberHandler(memberId);
          }}
        >
          {t('Team.Inactivate')}
        </button>
      );
    }
  }

  if (error) {
    return (
      <div>
        <p>
          {t('General.PageLoadError')} {error}
        </p>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <p>{t('Team.PageLoading')}</p>
      </div>
    );
  }

  if (redirecting) {
    return (
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="box">
                <h4 className="title is-4 has-text-centered">{t('Upgrade.ConnectingToStripe')}</h4>
                <h5 className="title is-5 has-text-centered">{t('Upgrade.ThanksForPatience')}</h5>
                <div className="has-text-centered">
                  <progress className="progress is-small is-primary" max="100">
                    15%
                  </progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (value && valueMember && subscription) {
    const members = valueMember.docs
      .map(queryDocumentSnapshotToMember)
      .sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    const invites = value.docs
      .map(queryDocumentSnapshotToInvite)
      .filter((i: InviteProps) => !members.find((m: MemberProps) => i.id === m.email));
    const [membersLength, inactiveTeamMembersLength, invitesLength] = [members.length, inactiveTeamMembers.length, invites.length];
    const remaining = subscription.quantity - membersLength - invitesLength + inactiveTeamMembersLength;
    return (
      <React.Fragment>
        <section>
          <h1 className="title is-2">{t('Team.InvitesTitle')}</h1>
          {!user.isTeamMember && user.role === 'basic' && renderInviteForm(membersLength, inactiveTeamMembersLength, invitesLength)}
          {!user.isTeamMember && user.role !== 'basic' && renderDemoUserNotice()}

          <table className="table is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>{t('Team.InvitesHeaderEmail')}</th>
                <th>{t('Team.InvitesHeaderSent')}</th>
                <th>{t('Team.InvitesHeaderActions')}</th>
              </tr>
            </thead>
            <tbody>
              {invites.map((invite, i) => (
                <tr key={i}>
                  <td>{invite.id}</td>
                  <td>{invite.lastInviteSent ? invite.lastInviteSent.toLocaleString(i18n.language) : ''}</td>
                  <td>
                    <div className="field is-grouped buttons are-small">
                      <p className="control">
                        {user.isTeamMember ? null : (
                          <button
                            className="button is-danger"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              event.preventDefault();
                              removeInviteHandler(invite.id);
                            }}
                          >
                            {t('General.Delete')}
                          </button>
                        )}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section style={{ paddingTop: '30px' }}>
          <h1 className="title is-2">{t('Team.TeamTitle')}</h1>

          <table className="table is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>{t('Team.InvitesHeaderName')}</th>
                <th>{t('Team.InvitesHeaderEmail')}</th>
                <th>{t('Team.InvitesHeaderRole')}</th>
                <th>{t('Team.InvitesHeaderJoined')}</th>
                <th>{t('Team.InvitesHeaderActions')}</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, i) => (
                <tr key={i}>
                  <td>
                    <ProfileCard user={member}></ProfileCard>
                  </td>
                  <td>{member.email}</td>
                  <td>{member.id === userTeam ? 'Account Manager' : 'Team Member'}</td>
                  <td>{member.createdAt ? member.createdAt.toLocaleDateString(i18n.language) : ''}</td>
                  <td>{renderMemberActionButton(member.id, inactiveTeamMembers, remaining)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </React.Fragment>
    );
  }

  return null;
};

export default TeamsPage;
