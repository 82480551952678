import React from 'react';
import { useHistory } from 'react-router';

type propType = {
  text: string;
  route: string;
  onClick: any;
};
const NavbarItem = ({ text, route, onClick }: propType) => {
  const history = useHistory();

  const navigate = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onClick(false);
    history.push(route);
  };

  return (
    <a className="navbar-item is-capitalized" onClick={navigate} href="#test" id={`page-link-${route.replace('/', '')}`}>
      {text}
    </a>
  );
};

export default NavbarItem;
