import { rootReducer } from './root-reducer';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

const middleware = [
  thunkMiddleware // Allows dispatching functions AKA action creators
];

let composeEnhancers = compose;

// In development mode we will enable react and redux dev tools
if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);

  middleware.push(
    createLogger({
      collapsed: true // keep messages collapsed in console by default
    })
  );

  // Enables redux devtools if installed in the browser
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const AppStore = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
