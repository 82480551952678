import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  auth,
  createUserProfileDocument,
  signInWithGoogle,
  signInWithTwitter,
  signInWithFacebook, /*, signInWithMicrosoft*/
  hasInvite
} from '../firebase';
import useForm from '../useForm';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faGoogle, faTwitter, faFacebook /*, faMicrosoft*/ } from '@fortawesome/free-brands-svg-icons';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import PublicLanguageChanger from '../components/PublicLanguageChanger';
import logo from '../assets/remotely-logo-04-cropped.png';
import { getValueFromQueryString } from '../utilities';

const SignUpPage: React.FC = () => {
  const user = useSelector(getUser);
  const { t, i18n } = useTranslation('translation');
  const [inviteMessage, setInviteMessage] = useState('');
  const email = getValueFromQueryString('email');

  useEffect(() => {
    if (!email) {
      return;
    }

    getEmailInvite();
    async function getEmailInvite() {
      const found = await hasInvite(email + '1');
      if (!found) {
        setInviteMessage(t('SignUp.InviteNotFound'));  
      }
    }
  }, [email, t]);
  

  const signUp = async () => {
    try {
      setError('');
      auth.languageCode = i18n.language;
      const { user } = await auth.createUserWithEmailAndPassword(values.email, values.password);
      if (user) {
        createUserProfileDocument(user, { displayName: values.name, language: i18n.language });
      } else {
        throw Error('User not created during execution of: createUserWithEmailAndPassword');
      }
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const signUpWithProvider = async (func: any, funcName: string) => {
    try {
      setError('');
      auth.languageCode = i18n.language;
      const { user } = await func();
      if (!user) {
        throw Error(`User not created during execution of: ${funcName}`);
      }
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  function renderSignUpForm() {
    return (
      <>
        <p className="title">{t('SignUp.Title')}</p>
        <p className="subtitle">{t('SignUp.SubTitle')}</p>
        {inviteMessage && <div className="notification is-danger">{inviteMessage}</div>}
        <div className="signin-form">
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">{t('SignUp.NameLabel')}</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="name"
                  placeholder={t('SignUp.NamePlaceholder')}
                  value={values.name || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">{t('SignUp.EmailLabel')}</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="email"
                  placeholder={t('SignUp.EmailPlaceholder')}
                  value={values.email || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">{t('SignUp.PasswordLabel')}</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="password"
                  placeholder={t('SignUp.PasswordPlaceholder')}
                  value={values.password || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
            {error && <div className="notification is-danger">{error}</div>}
            <button className="button is-fullwidth is-primary" type="submit">
              {t('SignUp.CreateAccountButton')}
            </button>
          </form>
        </div>
        <h6 className="is-6 has-text-centered signin-spacer">{t('SignUp.Or')}</h6>
        <div className="has-text-centered social-signin">
          <button
            className="button is-google is-fullwidth"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              signUpWithProvider(signInWithGoogle, 'signInWithGoogle');
            }}
          >
            <Icon icon={faGoogle} />
            &nbsp;&nbsp;
            <span>{t('SignUp.SignInWithGoogle')}</span>
          </button>
        </div>

        <div className="has-text-centered social-signin">
          <button
            className="button is-facebook is-fullwidth"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              signUpWithProvider(signInWithFacebook, 'signInWithFacebook');
            }}
          >
            <Icon icon={faFacebook} />
            &nbsp;&nbsp;
            <span>{t('SignUp.SignInWithFacebook')}</span>
          </button>
        </div>

        <div className="has-text-centered social-signin">
          <button
            className="button is-twitter is-fullwidth"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              signUpWithProvider(signInWithTwitter, 'signInWithTwitter');
            }}
          >
            <Icon icon={faTwitter} />
            &nbsp;&nbsp;
            <span>{t('SignUp.SignInWithTwitter')}</span>
          </button>
        </div>

        {/* <div className="has-text-centered social-signin">
          <button
            className="button is-microsoft is-fullwidth"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              signUpWithProvider(signInWithMicrosoft, 'signInWithMicrosoft');
            }}
          >
            <Icon icon={faMicrosoft} />
            &nbsp;&nbsp;
            <span>{t('SignUp.SignInWithMicrosoft')}</span>
          </button>
        </div> */}
      </>
    );
  }

  // TODO: Remember this method when toggling availability
  // function renderNotReadyMessage() {
  //   return (
  //     <>
  //       <div className="notification is-info">
  //         {t('SignUp.NotReadyMessage1')}
  //         <a href="mailto:support@remotelyinterview.com">support@remotelyinterview.com</a>
  //         {t('SignUp.NotReadyMessage2')}
  //       </div>
  //     </>
  //   );
  // }

  const { values, handleChange, handleSubmit }: any = useForm(signUp);
  const [error, setError] = useState('');

  if (user.team) {
    return <Redirect to={{ pathname: '/questions' }} />;
  } else {
    return (
      <>
        <div className="level signin-logo">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                <img src={logo} className="App-logo" alt="Remotely Interview Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="level signin">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                {renderSignUpForm()}
                {/* {process.env.NODE_ENV === 'production' && renderNotReadyMessage()}
                {process.env.NODE_ENV === 'development' && renderSignUpForm()} */}
              </div>
              <Link to={`/signin?lang=${i18n.language}`}>
                <footer className="card-footer">
                  <p className="card-footer-item register">
                    <span>{t('SignUp.SignInButton')}</span>
                  </p>
                </footer>
              </Link>
            </div>
          </div>
        </div>
        <PublicLanguageChanger></PublicLanguageChanger>
      </>
    );
  }
};

export default SignUpPage;
