import { mountPoint, TUser } from './reducer';
import { AppState } from '../root-reducer';

const getStateSlice = (state: AppState): TUser => state[mountPoint];

export const getUser = (state: AppState): TUser => {
  return getStateSlice(state);
};

export const getUserTeam = (state: AppState): string => {
  return getUser(state).team;
};
