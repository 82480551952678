import React from 'react';
import { useTranslation } from 'react-i18next';
import { getValueFromQueryString } from '../utilities';
import Notification from '../components/Notification';

const ErrorPage = () => {
  const { t } = useTranslation('translation');
  const code = getValueFromQueryString('code');

  function renderMessage(errorCode: string) {
    const message = t(`Error.ErrorCode-${errorCode}`);
    if (message && message !== `Error.ErrorCode-${errorCode}`) {
        return (
            <Notification isError={true}>{message}</Notification>
        );
    } else {
        return (
            <Notification isError={true}>
                {t('Error.ErrorCode-general')}
                <a href="mailto:support@remotelyinterview.com">support@remotelyinterview.com</a>
            </Notification>
        );
        
    }
  }

  return (
    <section>
      <div className="columns">
        <div className="column is-half">
          <div className="content">
            {renderMessage(code)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
