import {
  firestore,
  deleteCollection,
  getTemplatSelectedQuestions
  // initInterviewQuestionsRemote
} from '../../../firebase';
import { documentSnapshotToInterview, queryDocumentSnapshotToInterviewQuestion } from '../../../utilities';
import { TInterviewQuestion, TInterview } from '../../../types';
import { debounce } from '../../../utilities';
import { getInterview } from './selectors';
import { getUserTeam } from '../../../stores/user/selectors';

export const loadInterview = (interviewId: string) => async (dispatch: any, getState: any) => {
  const userTeam = getUserTeam(getState());
  const interviewRef = firestore.collection(`teams/${userTeam}/interviews`).doc(interviewId);
  const interviewDocumentSnapshot = await interviewRef.get();
  const interview = documentSnapshotToInterview(interviewDocumentSnapshot);

  if (!interview.startDate) {
    await deleteCollection(`teams/${userTeam}/interviews/${interview.id}/interviewQuestions`);
    const interviewQuestionsRef = firestore.collection(
      `teams/${userTeam}/interviews/${interview.id}/interviewQuestions`
    );
    const questionsRef = firestore
      .collection(`teams/${userTeam}/questions`)
      .where('templates', 'array-contains', interview.templateId);

    let [questionsQuerySnapshot, questionPositions] = await Promise.all([
      questionsRef.get(),
      getTemplatSelectedQuestions(userTeam, interview.templateId)
    ]);

    questionsQuerySnapshot.docs.forEach(async x => {
      let data = x.data();
      data.position = questionPositions.findIndex((o: any) => o.id === x.id);
      await interviewQuestionsRef.doc(x.id).set(data);
    });
  }

  dispatch({
    type: 'INTERVIEW_IN_PROGRESS_SET_INTERVIEW',
    payload: interview
  });

  dispatch(loadQuestions(interview.id));
};

export const loadQuestions = (interviewId: string) => async (dispatch: any, getState: any) => {
  const userTeam = getUserTeam(getState());

  const interviewQuestions = await firestore
    .collection(`teams/${userTeam}/interviews/${interviewId}/interviewQuestions`)
    .orderBy('position', 'asc')
    .get();

  const questions = interviewQuestions.docs.map(queryDocumentSnapshotToInterviewQuestion);

  // const questions = await initInterviewQuestionsRemote(user.team, interview);

  dispatch({
    type: 'INTERVIEW_IN_PROGRESS_SET_QUESTIONS',
    payload: { questions }
  });
};

export const handleInterviewChange = (interview: TInterview) => (dispatch: any, getState: any) => {
  // first update the store to instantly reflect the change
  dispatch({
    type: 'INTERVIEW_IN_PROGRESS_SET_INTERVIEW',
    payload: { ...interview }
  });

  const userTeam = getUserTeam(getState());

  saveInterview(interview, userTeam);
};

export const handleQuestionChange = (question: TInterviewQuestion) => (dispatch: any, getState: any) => {
  // first update the store to instantly reflect the change
  dispatch({
    type: 'INTERVIEW_IN_PROGRESS_SET_QUESTION',
    payload: { ...question }
  });

  const interview = getInterview(getState());
  const userTeam = getUserTeam(getState());

  // Ensure start date gets updated
  if (!interview.startDate) {
    interview.startDate = new Date();
    dispatch({
      type: 'INTERVIEW_IN_PROGRESS_SET_INTERVIEW',
      payload: interview
    });
    saveInterview(interview, userTeam);
  }

  saveInterviewQuestion(question, interview, userTeam);
};

const saveInterviewQuestion = debounce((question: TInterviewQuestion, interview: TInterview, userTeam: string) => {
  firestore
    .collection(`teams/${userTeam}/interviews/${interview.id}/interviewQuestions`)
    .doc(question.id)
    .update({
      candidateResponse: question.candidateResponse || null,
      candidateRating: question.candidateRating || null
    });
}, 2000);

const saveInterview = debounce((interview: TInterview, userTeam: string) => {
  firestore
    .collection(`teams/${userTeam}/interviews`)
    .doc(interview.id)
    .update({
      notes: interview.notes,
      startDate: interview.startDate ? interview.startDate : new Date()
    });
}, 2000);
