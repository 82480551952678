import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import { auth } from '../firebase';

const ProfilePasswordPage = () => {
  const user = useSelector(getUser);
  const history = useHistory();
  const [profile, setProfile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const { t, i18n } = useTranslation('translation');

  useEffect(() => {
    if (!user || !user.team) {
      return;
    }

    getProfileLocal();
    async function getProfileLocal() {
      setProfile({ ...user });
    }
  }, [user]);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setLoading(true);
    auth.languageCode = i18n.language;
    await auth.sendPasswordResetEmail(user.email);
    setLoading(false);
    setSent(true);
  };

  const cancelSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/profile');
  };

  function renderMessage() {
    return <div className="notification is-info">{t('ProfilePassword.Message')}</div>;
  }

  function renderSuccess() {
    return <div className="notification is-success">{t('ProfilePassword.SuccessMessage')}</div>;
  }

  if (!profile) {
    return null;
  }

  return (
    <section>
      <h1 className="title is-2">{t('ProfilePassword.Title')}</h1>
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">{t('Profile.EmailLabel')}</label>
            <div className="control is-expanded">
              <span className="mock-input-content">{user.email}</span>
            </div>
          </div>

          {!sent && renderMessage()}
          {sent && renderSuccess()}

          <div className="field is-grouped">
            <div className="control">
              <button type="submit" className={`button is-link ${loading ? 'is-loading' : ''}`} onClick={handleSubmit}>
                {t('ProfilePassword.SendPasswordReset')}
              </button>
            </div>
            <div className="control">
              <button className="button is-text" onClick={cancelSubmit}>
                {t('General.CancelButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePasswordPage;
