import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

const MenuButton = forwardRef((props: { children: any; content: any }, ref) => {
  const [activeProfileMenu, setActiveProfileMenu] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useImperativeHandle(ref, () => ({
    closeMenu() {
      setActiveProfileMenu(false);
    }
  }));

  const toggleProfileMenu = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveProfileMenu(!activeProfileMenu);
  };

  function useOutsideAlerter(ref: React.MutableRefObject<any>) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActiveProfileMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div ref={wrapperRef} className={'dropdown ' + (activeProfileMenu ? 'is-active' : '')}>
      <div className="dropdown-trigger">
        <button className="button" aria-haspopup="true" aria-controls="dropdown-menu2" onClick={toggleProfileMenu}>
          <span>{props.content}</span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu2" role="menu">
        <div className="dropdown-content-child">{props.children}</div>
      </div>
    </div>
  );
});

export default MenuButton;
