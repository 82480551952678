import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { getUser } from '../stores/user/selectors';

const ProfilePasswordPage = () => {
  // const user = useSelector(getUser);
  const { t } = useTranslation('translation');

  return (
    <section>
      <h1 className="title is-2">{t('UpgradeSuccess.Congrats')}</h1>
      <div className="columns">
        <div className="column is-half">
          <div className="content">
            <p>{t('UpgradeSuccess.SupportMessage')}</p>
            <p>
              <Link to="/questions" className="button is-success">{t('Menu.Questions')}</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePasswordPage;
