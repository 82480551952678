import React, { useEffect, useState } from 'react';
import { match as Imatch } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router';
import { getInterview, deleteInterview } from '../firebase';
import { useTranslation } from 'react-i18next';
import Notification from '../components/Notification';
import { useSelector } from 'react-redux';
import { getUserTeam } from '../stores/user/selectors';
import { TInterview } from '../types';

const InterviewPage = () => {
  const userTeam = useSelector(getUserTeam);
  const [interview, setInterview] = useState<TInterview>();
  const { t } = useTranslation('translation');
  const history = useHistory();
  const match: Imatch<{ id: string }> = useRouteMatch();

  useEffect(() => {
    if (!userTeam) {
      return;
    }

    getInterviewLocal();
    async function getInterviewLocal() {
      const interview = await getInterview(userTeam, match.params.id);
      setInterview(interview);
    }
  }, [match.params.id, userTeam]);

  if (!interview) {
    return null;
  }

  return (
    <section>
      <h1 className="title is-2">{t('Interview.InterviewTitle')}</h1>
      <Notification value={t('Interview.DeleteConfirm')} />
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">{t('Interview.CandidateNameLabel')}</label>
            <div className="control is-expanded mock-input">{interview.candidateName}</div>
          </div>

          <div className="field">
            <label className="label">{t('Interview.InterviewerLabel')}</label>
            <div className="control is-fullwidth mock-input">{interview.userDisplayName}</div>
          </div>

          <div className="field">
            <label className="label">{t('Interview.PositionLabel')}</label>
            <div className="control is-fullwidth mock-input">{interview.templateName}</div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-link"
                onClick={async (event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  deleteInterview(userTeam, interview.id);
                  history.push('/interviews');
                }}
              >
                {t('General.Delete')}
              </button>
            </div>
            <div className="control">
              <button
                className="button is-text"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  history.push('/interviews');
                }}
              >
                {t('General.CancelButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InterviewPage;
