import React, { useContext, useEffect, useState } from 'react';
import { match as Imatch, Link } from 'react-router-dom';
import { useRouteMatch, useHistory } from 'react-router';
import { saveInterview, getTeamMembers, getInterview, getInterviewsWithLimit } from '../firebase';
import { TemplatesContext } from '../providers/TemplatesContext';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import Notification from '../components/Notification';
import { hasReachedDemoLimit, APP_PARAMS, isMemberInExpiredTeam } from '../utilities';
import NotificationForDemo from '../components/NotificationForDemo';

const InterviewPage = () => {
  const user = useSelector(getUser);
  const { templates }: any = useContext(TemplatesContext);
  const [interview, setInterview] = useState<any>();
  const [members, setMembers] = useState<any[]>();
  const [demoLimitReached, setDemoLimitReached] = useState(false);
  const [isButtonSaving, setIsButtonSaving] = React.useState(false);
  const [isButtonStarting, setIsButtonStarting] = React.useState(false);
  const { t } = useTranslation('translation');
  const match: Imatch<{ id: string }> = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (!user || !user.team) {
      return;
    }

    getInterviewLocal();
    async function getInterviewLocal() {
      // Setup the queries
      let [members, interview] = await Promise.all([
        getTeamMembers(user.team),
        getInterview(user.team, match.params.id)
      ]);

      // Only check demo limit if this is a new question
      if (match.params.id === '0') {
        const interviews = await getInterviewsWithLimit(user.team, APP_PARAMS.demoInterviewsLimit);
        setDemoLimitReached(hasReachedDemoLimit('interviews', interviews, user));
      }

      // Set the state
      setMembers(members);

      // TODO: Is this the best way/spot to do this
      if (!interview.userId) {
        interview.userId = user.uid;
      }

      setInterview(interview);
    }
  }, [match.params.id, user]);

  // This effect is just for making sure that the 1st/default template is chosen on load
  useEffect(() => {
    if (!user || !user.team || !templates || !interview) {
      return;
    }

    getLocal();
    async function getLocal() {
      if (!interview.templateId && templates.length > 0) {
        interview.templateId = templates[0].id;
      }
    }
  }, [match.params.id, user, templates, interview]);

  // so not a fan of any here... VS a proper type
  // but I can't find a way to type checkboxes AND textareas
  // in a way TS will be happy.... so any it is today...
  // its better then two handle change functions doing the same thing
  const handleChange = ({ target }: any) => {
    let { name, type } = target;
    let value = type === 'checkbox' ? target.checked : target.value;

    setInterview({ ...interview, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    setIsButtonSaving(true);
    const id = await saveInterviewLocal();
    setIsButtonSaving(false);
    history.push(`/interviews?interviewSaved=${id}`);
  };

  const cancelEdit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/interviews');
  };

  const saveAndStart = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validate()) {
      return;
    }

    setIsButtonStarting(true);
    await saveInterviewLocal()
    setIsButtonStarting(false);;
    history.push(`/interviewInProgress/${interview.id}`);
  };

  const saveInterviewLocal = async () => {
    // TODO: Better way to get this? Like off of the form? Also don't like using any... MemberProps?
    interview.userDisplayName = members.find((m: { id: any }) => m.id === interview.userId).displayName;
    interview.templateName = templates.find((t: { id: any }) => t.id === interview.templateId).name;
    return await saveInterview(user.team, interview);
  };

  const validate = () => {
    return interview.candidateName;
  };

  if (!interview || !templates) {
    return null;
  }

  if (demoLimitReached) {
    if (isMemberInExpiredTeam(user)) {
      return <Notification isError={false} value={t('General.ExpiredTeam')} />;
    } else {
      return <NotificationForDemo value={t('Interviews.InterviewsLimitReached')} />;
    }
  }

  if (templates && templates.length === 0) {
    return (
      <section>
        <h1 className="title is-2">{t('Interview.InterviewTitle')}</h1>
        <div className="columns">
          <div className="column is-half">
            <Notification value={t('Interview.NoPositionMessage')} />
            <Link to="/positions">{t('Interview.PositionLink')}</Link>
          </div>
        </div>
      </section>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <h1 className="title is-2">{t('Interview.InterviewTitle')}</h1>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label className="label">{t('Interview.CandidateNameLabel')}</label>
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  name="candidateName"
                  value={interview.candidateName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">{t('Interview.InterviewerLabel')}</label>
              <div className="control is-fullwidth">
                {members.find((m: { id: any }) => m.id === interview.userId).displayName}
              </div>
            </div>

            <div className="field">
              <label className="label">{t('Interview.PositionLabel')}</label>
              <div className="control select is-fullwidth">
                <select
                  name="templateId"
                  value={interview.templateId}
                  onChange={handleChange}
                  disabled={interview.startDate}
                >
                  {templates.map((t: any) => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {interview.startDate ? (
              <article className="message is-info">
                <div className="message-body">{t('Interview.PositionDisabled')}</div>
              </article>
            ) : null}

            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <div className="field is-grouped">
                    <div className="control">
                      <button type="submit" className={`button is-link ${isButtonSaving ? 'is-loading' : ''}`}>
                        {t('General.SaveButton')}
                      </button>
                    </div>
                    <div className="control">
                      <button className="button is-text" onClick={cancelEdit}>
                        {t('General.CancelButton')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <div className="field">
                    <div className="control">
                      <button className={`button is-info ${isButtonStarting ? 'is-loading' : ''}`} onClick={saveAndStart}>
                        {t('Interview.StartInterview')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};

export default InterviewPage;
