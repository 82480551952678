import React, { useContext, useState, useEffect } from 'react';
import { QuestionsContext } from '../providers/QuestionsContext';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  getQuestion,
  getTemplatSelectedQuestions,
  saveTemplateChangeToQuestion,
  saveTemplateSelectedQuestions
} from '../firebase';
import Fuse from 'fuse.js';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
  faGripVertical,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight /*, faEllipsisV*/
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { getUserTeam } from '../stores/user/selectors';
import * as Showdown from 'showdown';
import { showdownConverterOptions } from '../utilities';

const InterviewQuestion = ({ question }: any) => {
  const { t } = useTranslation('translation');

  const converter = new Showdown.Converter(showdownConverterOptions);

  if (!question || !question.question) {
    return <div>{t('General.Loading')}</div>;
  }

  return (
    <>
      <div className="field">
        <label className="label">
          <strong>{t('InterviewInProgress.QuestionLabel')}</strong>
        </label>
        <div
          className="control is-expanded view-question"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(question.question) }}
        ></div>
      </div>

      <div className="field">
        <label className="label">
          <strong>{t('InterviewInProgress.AnswerLabel')}</strong>
        </label>
        <div
          className="control is-expanded view-answer"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(question.answer) }}
        ></div>
      </div>
    </>
  );
};

const PositionOrg = (props: { templateId?: string }) => {
  const userTeam = useSelector(getUserTeam);
  const { questions, loading, error }: any = useContext(QuestionsContext);
  const [lists, setLists] = useState({ items: null, selected: null });
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedQuestionStatus, setSelectedQuestionStatus] = useState('closed');
  const [lastModifiedDate, setLastModifiedDate] = useState(new Date());
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation('translation');
  const history = useHistory();
  const [fuse, setFuse] = useState<Fuse<any, any>>();
  const showIcon = true;

  useEffect(() => {
    const fuseOptions = { keys: ['name', 'tags'] };
    setFuse(new Fuse(questions, fuseOptions));
  }, [questions]);

  const handleSearchChange = ({ target }: any) => {
    let searchTerm = target.value;

    const filteredList = fuse.search(searchTerm);

    const items = lists.items.map((q: any) => {
      q.isHidden = false;

      if (searchTerm) {
        q.isHidden = !filteredList.some((el: any) => el.id === q.id);
      }

      return q;
    });

    setSearchText(searchTerm);
    setLists({
      ...lists,
      items
    });
  };

  const addQuestionHandler = async () => {
    return history.push(`/question/0?position=${props.templateId}`);
  };

  const findSelectedQuestionsFromMaster = (master: any, sortedSubset: any) => {
    let result: Array<any> = [];

    sortedSubset.forEach((s: any) => {
      let match = master.find((m: any) => m.id === s.id);
      if (match) {
        result.push(match);
      }
    });

    return result;
  };

  const getSelectedQuestion = async (id: string, listName: string) => {
    if (selectedQuestion === id) {
      setSelectedQuestion('');
      setSelectedQuestionStatus('closed');
    } else {
      setSelectedQuestion(id);

      if (listName === 'droppable1') {
        const itemsIndex = lists.items.findIndex((i: any) => id === i.id);

        if (!lists.items[itemsIndex].question) {
          setSelectedQuestionStatus('opening');
          lists.items[itemsIndex] = await getQuestion(userTeam, id);
          setLists(lists);
        }
        setSelectedQuestionStatus('open');
      } else {
        const selectedIndex = lists.selected.findIndex((i: any) => id === i.id);

        if (!lists.selected[selectedIndex].question) {
          setSelectedQuestionStatus('opening');
          lists.selected[selectedIndex] = await getQuestion(userTeam, id);
          setLists(lists);
        }
        setSelectedQuestionStatus('open');
      }
    }
  };

  const idToList = (id: string) => {
    return id === 'droppable1' ? lists.items : lists.selected;
  };

  const updateListFromId = (id: string, list: Array<any>) => {
    if (id === 'droppable1') {
      lists.items = list;
    } else {
      lists.selected = list;
    }
  };

  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Moves an item from one list to another list.
  const move = (
    source: Array<any>,
    destination: Array<any>,
    droppableSourceIndex: number,
    droppableSourceId: any,
    droppableDestinationIndex: number,
    droppableDestinationId: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSourceIndex, 1);

    destClone.splice(droppableDestinationIndex, 0, removed);

    const result: any = {};
    result[droppableSourceId] = sourceClone;
    result[droppableDestinationId] = destClone;

    return result;
  };

  // BUG: question details after 2nd click
  // UX: Only 1 expanded section between both lists. Not sure there is a use case for 1 expanded item per list
  const getExpandedItemHeight = (isExpanded: boolean) => {
    if (isExpanded && selectedQuestionStatus === 'opening') {
      return 70;
    } else if (isExpanded && selectedQuestionStatus === 'open') {
      return 'auto';
    } else if (!isExpanded) {
      return 0;
    }
  };

  const getListStyle = (isDraggingOver: boolean) => {
    // TODO: Switch these from inline styles to class name changes
    return {
      backgroundColor: isDraggingOver ? 'lightgrey' : '#fff'
    };
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      background: isDragging ? '#8bc34a' : '#E1E2E1',
      ...draggableStyle
    };
  };

  function renderDndIcon() {
    return (
      <>
        <Icon icon={faGripVertical}  size={"1x"} style={{ color: 'darkgray', marginRight: '8px' }} />
      </>
    );
  }

  // Event handler will pass to function that does actual work. Allows for target function to be called manually
  const onDragEnd = async (result: DropResult) => {
    return moveItemInLists(result);
  };

  const manualSort = async (droppableId: string, sourceIndex: number, destinationIndex: number, max: number) => {
    if (destinationIndex < 0 || destinationIndex >= max) {
      return;
    }

    await moveItemInLists({
      source: { droppableId, index: sourceIndex },
      destination: { droppableId, index: destinationIndex },
      reason: 'DROP',
      type: 'DEFAULT',
      mode: 'FLUID',
      draggableId: 'manual'
    });
    setLastModifiedDate(new Date());
  };

  const manualMove = async (sourceDroppableId: string, destinationDroppableId: string, sourceIndex: number) => {
    await moveItemInLists({
      source: { droppableId: sourceDroppableId, index: sourceIndex },
      destination: { droppableId: destinationDroppableId, index: 0 },
      reason: 'DROP',
      type: 'DEFAULT',
      mode: 'FLUID',
      draggableId: 'manual'
    });
    setLastModifiedDate(new Date());
  };

  const moveItemInLists = async (result: DropResult) => {
    const { source, destination } = result;

    // Dropped outside of lists
    if (!destination) {
      return;
    }

    // Sorting within one of the two lists
    if (source.droppableId === destination.droppableId) {
      // Make sure the index changed
      if (destination.index === source.index) {
        return;
      }
      const items = reorder(idToList(source.droppableId), source.index, destination.index);
      updateListFromId(source.droppableId, items);
      await saveTemplateSelectedQuestions(userTeam, props.templateId, lists.selected);
      setLists(lists);
    } else {
      const items = move(
        idToList(source.droppableId),
        idToList(destination.droppableId),
        source.index,
        source.droppableId,
        destination.index,
        destination.droppableId
      );
      updateListFromId(source.droppableId, items[source.droppableId]);
      updateListFromId(destination.droppableId, items[destination.droppableId]);
      saveTemplateChangeToQuestion(
        items[destination.droppableId][destination.index].id,
        destination.droppableId === 'droppable2',
        userTeam,
        props.templateId
      );
      await saveTemplateSelectedQuestions(userTeam, props.templateId, lists.selected);
      setLists(lists);
    }
  };

  if (error) {
    return (
      <div>
        <p>
          {t('General.PageLoadError')} {error}
        </p>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <p>{t('Questions.PageLoading')}</p>
      </div>
    );
  }
  if (!questions && lists.items === null) {
    return null;
  }

  // Questions are available, but local list need to be initialized
  if (questions && lists.items === null) {
    // TODO: Technically should add user.team to the conditional above. However, QuestionContext isn't ready until team is available. So this is implicit
    getTemplatSelectedQuestions(userTeam, props.templateId).then(response => {
      setLists({
        items: questions.filter((o: any) => !response.find((o2: any) => o.id === o2.id)),
        selected: findSelectedQuestionsFromMaster(questions, response)
      });
    });
    return null;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="columns">
        <Droppable droppableId="droppable1">
          {(provided, snapshot) => (
            <div
              className="column is-half question-catalog"
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <h3 className="title is-3">{t('Position.QuestionCatalog')}</h3>

              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder={t('Questions.SearchPlaceholder')}
                    onChange={handleSearchChange}
                    value={searchText}
                  />
                  <input type="hidden" value={lastModifiedDate.toTimeString()} />
                </p>
              </div>

              {lists.items.map((q: any, index: number) => (
                <Draggable key={q.id} draggableId={q.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={`card is-fullwidth question ${q.isHidden ? 'is-hidden' : ''}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <header className="card-header">
                        <p
                          className="card-header-title"
                          data-cy={`itemsSelectQuestion${index}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            getSelectedQuestion(q.id, 'droppable1');
                          }}
                        >
                          {showIcon && renderDndIcon()}
                          {q.name}
                        </p>
                        <span
                          className={`card-header-icon card-toggle question-org-icon`}
                          data-cy={`itemsSortUp${index}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            manualSort('droppable1', index, index - 1, lists.items.length);
                          }}
                        >
                          <Icon icon={faArrowUp} />
                        </span>
                        <span
                          className={`card-header-icon card-toggle question-org-icon`}
                          data-cy={`itemsSortDown${index}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            manualSort('droppable1', index, index + 1, lists.items.length);
                          }}
                        >
                          <Icon icon={faArrowDown} />
                        </span>
                        <span
                          className={`card-header-icon card-toggle question-org-icon`}
                          data-cy={`itemsMove${index}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            manualMove('droppable1', 'droppable2', index);
                          }}
                        >
                          <Icon icon={faArrowRight} />
                        </span>
                        <a className="card-header-icon card-toggle" href="#test">
                          {selectedQuestion === q.id ? <Icon icon={faChevronDown} /> : <Icon icon={faChevronRight} />}
                        </a>
                      </header>
                      <AnimateHeight duration={500} height={getExpandedItemHeight(selectedQuestion === q.id)}>
                        <div className="card-content">
                          <div className="content">
                            <InterviewQuestion question={q} />
                          </div>
                        </div>
                      </AnimateHeight>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="droppable2">
          {(provided, snapshot) => (
            <div
              className="column is-half selected-questions-column"
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <h3 className="title is-3">{t('Position.SelectedQuestions')}</h3>

              <div className="field">
                <p className="control">
                  <button className="button is-success is-fullwidth" onClick={addQuestionHandler}>
                    {t('Questions.NewQuestionButton')}
                  </button>
                </p>
              </div>

              {lists.selected.map((q: any, index: number) => (
                <Draggable key={q.id} draggableId={q.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="card is-fullwidth question"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <header className="card-header">
                        <p
                          className="card-header-title"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            getSelectedQuestion(q.id, 'droppable2');
                          }}
                        >
                          {showIcon && renderDndIcon()}
                          {q.name}
                        </p>
                        <span
                          className={`card-header-icon card-toggle question-org-icon`}
                          data-cy={`selectedSortUp${index}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            manualSort('droppable2', index, index - 1, lists.selected.length);
                          }}
                        >
                          <Icon icon={faArrowUp} />
                        </span>
                        <span
                          className={`card-header-icon card-toggle question-org-icon`}
                          data-cy={`selectedSortDown${index}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            manualSort('droppable2', index, index + 1, lists.selected.length);
                          }}
                        >
                          <Icon icon={faArrowDown} />
                        </span>
                        <span
                          className={`card-header-icon card-toggle question-org-icon`}
                          data-cy={`selectedMove${index}`}
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            manualMove('droppable2', 'droppable1', index);
                          }}
                        >
                          <Icon icon={faArrowLeft} />
                        </span>
                        <a className="card-header-icon card-toggle" href="#test">
                          {selectedQuestion === q.id ? <Icon icon={faChevronDown} /> : <Icon icon={faChevronRight} />}
                        </a>
                      </header>
                      <AnimateHeight duration={500} height={getExpandedItemHeight(selectedQuestion === q.id)}>
                        <div className="card-content">
                          <div className="content">
                            <InterviewQuestion question={q} />
                          </div>
                        </div>
                      </AnimateHeight>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default PositionOrg;
