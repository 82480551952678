import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { getUser } from '../stores/user/selectors';

const ProfilePasswordPage = () => {
  // const user = useSelector(getUser);
  const { t } = useTranslation('translation');

  return (
    <section>
      <h1 className="title is-2">{t('Help.Title')}</h1>
      <div className="columns">
        <div className="column is-half">
          <div className="content">
            {t('Help.SupportMessage')}
            <a href="mailto:support@remotelyinterview.com">support@remotelyinterview.com</a>
          </div>
          <div className="content">{t('Help.SupportMessageLanguage')}</div>
          <div className="content">
            {t('Help.DocumentsMessage')}
            <ul>
              <li>
                <a href="http://www.remotelyinterview.com/terms" target="_blank" rel="noopener noreferrer">
                  {t('Help.Terms')}
                </a>
              </li>
              <li>
                <a href="http://www.remotelyinterview.com/privacy" target="_blank" rel="noopener noreferrer">
                  {t('Help.Privacy')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePasswordPage;
