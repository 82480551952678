import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addTemplateWithName } from '../firebase';

const AddPosition = (props: { team: string; onCancel: any; onSave: any }) => {
  const { team, onCancel, onSave } = props;
  const [name, setName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('translation');

  const cancelEdit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onCancel();
  };

  const saveEdit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    const positionId = await addTemplateWithName(team, name);
    onSave(positionId);
    setName('');
    setLoading(false);
  };

  const handleChange = ({ target }: any) => {
    let value = target.value;
    setName(value);
  };

  return (
    <article className="message is-dark add-position">
      <div className="message-header">
        <p>{t('General.AddPosition')}</p>
        <button className="delete" aria-label="delete" onClick={cancelEdit}></button>
      </div>
      <div className="message-body">
        <div className="field">
          <label className="label">{t('Question.NameLabel')}</label>
          <div className="control is-expanded">
            <input className="input" type="text" name="positionName" onChange={handleChange} value={name} />
          </div>
        </div>
        <div className="field is-grouped">
          <div className="control">
            <button type="submit" className={`button is-link ${loading ? 'is-loading' : ''}`} onClick={saveEdit}>
              {t('General.SaveButton')}
            </button>
          </div>
          <div className="control">
            <button className="button is-text" onClick={cancelEdit}>
              {t('General.CancelButton')}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AddPosition;
