import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ProfileCard from '../components/ProfileCard';
import { getInterviewQueryWithLimit, getTeamMembers, deleteInterview } from '../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';
import { queryDocumentSnapshotToInterview, isValidDate, arrayToMap, hasReachedDemoLimit, getValueFromQueryString, isMemberInExpiredTeam } from '../utilities';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';
import { getUserTeam, getUser } from '../stores/user/selectors';
import { TInterview } from '../types';
import NotificationForDemo from '../components/NotificationForDemo';
import Notification from '../components/Notification';
import { toast } from 'bulma-toast';
import Modal from "../components/Modal";
import useModal from '../useModal';

const InterviewsPage = () => {
  const userTeam = useSelector(getUserTeam);
  const user = useSelector(getUser);
  const [listLimit, setListLimit] = useState(20);
  const { i18n, t } = useTranslation('translation');
  // User and Team should exist. Route should not render this component without those

  const [value, loading, error] = useCollection(getInterviewQueryWithLimit(userTeam, listLimit));
  const [searchText, setSearchText] = useState('');
  const [interviewSaved, setInterviewSaved] = useState('');
  const [teamMembers, setTeamMembers] = useState<Map<any, any>>();
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [fuse, setFuse] = useState<Fuse<TInterview, any>>();
  const [demoLimitReached, setDemoLimitReached] = useState(false);
  const history = useHistory();
  const {isShowing, toggle} = useModal();

  useEffect(() => {
    let interviews: TInterview[];
    const fuseKeys = { keys: ['userDisplayName', 'candidateName', 'templateName'] };

    if (value) {
      interviews = value.docs.map(queryDocumentSnapshotToInterview);
      setFuse(new Fuse(interviews, fuseKeys));
      getMembersLocal();
    }

    if (!interviewSaved) {
      const id = getValueFromQueryString('interviewSaved');
      if (id) {
        toast({ message: t('Interviews.InterviewSaved'), type: 'is-success', position: 'top-center', dismissible: true, pauseOnHover: true, duration: 6000, animate: { in: 'fadeIn', out: 'fadeOut' } });
        setInterviewSaved(id);
        history.push("/interviews");
      }
    }

    setDemoLimitReached(hasReachedDemoLimit('interviews', interviews, user));

    async function getMembersLocal() {
      const members = await getTeamMembers(userTeam);
      setTeamMembers(arrayToMap(members));
    }
  }, [value, user, userTeam, listLimit, history, interviewSaved, t]);

  const handleChange = ({ target }: any) => {
    let value = target.value;
    setSearchText(value);
  };

  const deleteInterviewHandler = async (interview: any) => {
    setSelectedInterview(interview);
    toggle();
  };

  const confirmDeleteHandler = async () => {
    await deleteInterview(userTeam, selectedInterview.id);
    toast({ message: t('Interviews.InterviewDeleted'), type: 'is-success', position: 'top-center', dismissible: true, pauseOnHover: true, duration: 6000, animate: { in: 'fadeIn', out: 'fadeOut' } });
    setSelectedInterview(null);
    toggle();
  };

  const sortInterviews = (a: any, b: any) => {
    const aIsValid = isValidDate(a.startDate);
    const bIsValid = isValidDate(b.startDate);

    // Both are null fall back to created date
    if (!aIsValid && !bIsValid) {
      if (isValidDate(a.createdDate) && isValidDate(b.createdDate)) {
        return b.createdDate.getTime() - a.createdDate.getTime();
      } else {
        return 0;
      }
    } else if (!aIsValid) {
      return -1;
    } else if (!bIsValid) {
      return 1;
    } else {
      return b.startDate.getTime() - a.startDate.getTime();
    }
  };

  const addInterviewHandler = async () => {
    return history.push(`/interview/0`);
  };

  const navigateToInterview = (event: any) => {
    if (event.target.nodeName === 'A' || event.target.nodeName === 'BUTTON') {
      return;
    }

    const el = event.target.closest('tr[data-item]');
    let id = null;

    if (el) {
      id = el.getAttribute('data-item');
    }

    if (id) {
      history.push(`/interview/${id}`);
    }
  };

  const showMoreHandler = () => {
    setListLimit(100);
  };

  const filterInterviews = (list: any) => {
    // Note: only applying sort on non-filtered results since fuse is scoring results
    return searchText && fuse ? fuse.search(searchText) : list.sort(sortInterviews);
  };

  if (error) {
    return (
      <div>
        <p>
          {t('General.PageLoadError')} {error}
        </p>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <p>{t('Interviews.PageLoading')}</p>
      </div>
    );
  }

  if (!value || !teamMembers) {
    return null;
  }

  return (
    <section>
      {demoLimitReached && isMemberInExpiredTeam(user) && <Notification value={t('General.ExpiredTeam')} />}
      {demoLimitReached && !isMemberInExpiredTeam(user) && <NotificationForDemo value={t('Interviews.InterviewsLimitReached')} />}
      <h1 className="title is-2">{t('Interviews.InterviewsTitle')}</h1>
      <div className="columns">
        <div className="column is-three-quarters">
          <div className="field">
            <p className="control">
              <input
                className="input"
                type="text"
                placeholder={t('Interviews.SearchPlaceholder')}
                onChange={handleChange}
                value={searchText}
              />
            </p>
          </div>
        </div>
        <div className="column">
          <button disabled={demoLimitReached} className="button is-success is-fullwidth" onClick={addInterviewHandler}>
            {t('Interviews.NewInterviewButton')}
          </button>
        </div>
      </div>

      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>{t('Interviews.InterviewsHeaderUserDisplayName')}</th>
            <th>{t('Interviews.InterviewsHeaderCandidateName')}</th>
            <th>{t('Interviews.InterviewsHeaderPositionName')}</th>
            <th>{t('Interviews.InterviewsHeaderCreatedDate')}</th>
            <th>{t('Interviews.InterviewsHeaderStartDate')}</th>
            <th>{t('Interviews.InterviewsHeaderActions')}</th>
          </tr>
        </thead>
        <tbody>
          {filterInterviews(value.docs.map(queryDocumentSnapshotToInterview)).map((v: any) => (
            <tr key={v.id} data-item={v.id} onClick={navigateToInterview}>
              <td>
                <ProfileCard user={teamMembers.get(v.userId)} hasBackgroundDark={true}></ProfileCard>
              </td>
              <td>{v.candidateName}</td>
              <td>{v.templateName}</td>
              <td>{v.createdDate ? v.createdDate.toLocaleDateString(i18n.language) : ''}</td>
              <td>{v.startDate ? v.startDate.toLocaleDateString(i18n.language) : ''}</td>
              <td>
                <div className="field is-grouped buttons are-small">
                  <p className="control">
                    <Link
                      className="button is-success"
                      to={{ pathname: `/interview/${v.id}`, state: { interview: v } }}
                    >
                      {t('General.Edit')}
                    </Link>
                  </p>

                  <p className="control">
                    <Link
                      className="button is-info"
                      to={{
                        pathname: `/interviewInProgress/${v.id}`,
                        state: {
                          interview: v
                        }
                      }}
                    >
                      {t('Interviews.Interview')}
                    </Link>
                  </p>
                  <p className={'control ' + ((user.isTeamMember && v.startDate) ? 'is-hidden' : '')}>
                  <button className="button is-danger" onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        deleteInterviewHandler(v);
                      }}>{t('General.Delete')}</button>
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {value.docs.length === 20 && (
        <button className="button is-text is-fullwidth" onClick={showMoreHandler}>
          {t('Interviews.ShowMore')}
        </button>
      )}
      <Modal isShowing={isShowing} title={t('General.ConfirmTitle')} hide={toggle} confirm={confirmDeleteHandler} >
        <div>
          <div>{t('Interview.DeleteConfirm')}</div>
          <div>{ selectedInterview ? selectedInterview.candidateName : '' }</div>
          <div>{ selectedInterview ? selectedInterview.templateName : '' }</div>
        </div>
      </Modal>
    </section>
  );
};

export default InterviewsPage;
