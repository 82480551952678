import { TInterviewQuestion, TInterview } from '../../../types';

export const mountPoint = 'InterviewInProgress';

export interface InterviewInProgressState {
  interview: TInterview | any;
  questions: TInterviewQuestion[] | any;
}

const initialState: InterviewInProgressState = {
  interview: {},
  questions: []
};

export const reducer = (state: InterviewInProgressState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case 'INTERVIEW_IN_PROGRESS_SET_INTERVIEW':
      return { ...state, interview: payload };

    case 'INTERVIEW_IN_PROGRESS_SET_QUESTIONS':
      return { ...state, questions: payload.questions };

    case 'INTERVIEW_IN_PROGRESS_SET_QUESTION':
      const question = payload;
      const questionIndex = state.questions.findIndex((q: TInterviewQuestion) => q.id === question.id);
      state.questions[questionIndex] = question;

      return { ...state };

    case 'INTERVIEW_IN_PROGRESS_UNLOAD':
      return { ...initialState };

    default:
      return state;
  }
};
