import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// TODO: I would like to set this to register and operate as a PWA. However, before doing so
// I need to figure out an effective way to cache bust this for my users. Currently I'm only
// able to clear the cache via a hard refresh.
serviceWorker.unregister();
