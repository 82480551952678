import React from 'react';

const Notification = (props: { children?: any; value?: string, isError?: boolean }) => {
  const { children, value, isError } = props;

  // TODO: This is only the beginning for this. Other features like:
  // 1) Close on (x)
  // 2) Animate?
  // 3) Close on timer? 10s?
  // 4) Maybe make this a consistent item in the site?
  return (!value && !children) ? null : (
    <div className={'notification ' + (isError ? 'is-danger' : 'is-info')}>
      <button className="delete"></button>
      {value}
      {children}
    </div>
  );
};

export default Notification;
