import { Tag, QuestionProps, TInterviewQuestion, InviteProps, MemberProps, TemplateProps, TInterview } from './types';
import { TUser } from './stores/user/reducer';

//#region Constants for app
export const APP_PARAMS = {
  demoInterviewsLimit: 2,
  demoQuestionsLimit: 5
};
//#endregion

export const tagsToArray = (tags: Array<Tag>) => {
  let tagsArray: string[] = [];

  if (!Array.isArray(tags)) {
    return tagsArray;
  }

  tags.forEach(tag => {
    tagsArray.push(tag.name);
  });
  return tagsArray;
};

export const arrayToTags = (tags: Array<string>, startIndex?: number) => {
  startIndex = startIndex || 0;
  let tagsArray: Tag[] = [];

  tags.forEach((tag, index) => {
    tagsArray.push({ id: index + startIndex, name: tag });
  });
  return tagsArray;
};

export const getTagsFromQuestions = (questions: Array<any>): Array<Tag> => {
  const counts: any[] = [];
  questions.forEach(q => {
    q.tags.forEach((t: any) => {
      if (counts[t] === undefined) {
        counts[t] = 0;
      }
      counts[t]++;
    });
  });

  const sortedKeys = getSortedKeys(counts);
  const tags = arrayToTags(sortedKeys, 100);
  return tags;

  //   let tags: Tag[] = [];

  //   for (var key in counts) {
  //     var value = array[key];
  //     console.log(key, value);
  // }
};

export const getSortedKeys = (obj: any) => {
  var keys = Object.keys(obj);
  return keys.sort(function(a, b) {
    return obj[b] - obj[a];
  });
};

export const arrayToCsv = (tags: Array<string>) => {
  if (!Array.isArray(tags)) {
    return '';
  }

  return tags.join(', ');
};

export const arrayToMap = (items: Array<any>) => {
  const result = new Map();
  items.forEach((item: any) => {
    if (item.id) {
      result.set(item.id, item);
    }
  });
  return result;
};

export const isValidDate = (value: any) => {
  return value && Object.prototype.toString.call(value) === '[object Date]' && !isNaN(value);
};

export const queryDocumentSnapshotToQuestion = (doc: firebase.firestore.QueryDocumentSnapshot) => {
  let data = doc.data();
  // TODO: Why wasn't I able to use destructuring? TS error with: { id: doc.id, ...doc.data() }
  let q: QuestionProps = {
    id: doc.id,
    name: data.name,
    private: data.private,
    tags: data.tags,
    templates: data.templates,
    question: data.question,
    answer: data.answer,
    owner: data.owner,
    published: data.published || false
  };
  return q;
};

export const queryDocumentSnapshotToInterviewQuestion = (doc: firebase.firestore.QueryDocumentSnapshot) => {
  let data = doc.data();
  // TODO: Why wasn't I able to use destructuring? TS error with: { id: doc.id, ...doc.data() }
  let q: TInterviewQuestion = {
    id: doc.id,
    name: data.name,
    private: data.private,
    tags: data.tags,
    templates: data.templates,
    question: data.question,
    answer: data.answer,
    owner: data.owner,
    published: data.published || false,
    candidateResponse: data.candidateResponse,
    candidateRating: data.candidateRating
  };
  return q;
};

export const documentSnapshotToQuestion = (doc?: firebase.firestore.DocumentSnapshot) => {
  if (doc && doc.exists) {
    let q: QuestionProps = {
      id: doc.id,
      name: doc.get('name'),
      private: doc.get('private'),
      tags: doc.get('tags'),
      templates: doc.get('templates'),
      question: doc.get('question'),
      answer: doc.get('answer'),
      owner: doc.get('owner'),
      published: doc.get('published') || false
    };
    return q;
  } else {
    return null;
  }
};

export const queryDocumentSnapshotToInvite = (doc: firebase.firestore.QueryDocumentSnapshot) => {
  let data = doc.data();
  // TODO: Why wasn't I able to use destructuring? TS error with: { id: doc.id, ...doc.data() }
  let i: InviteProps = {
    id: doc.id,
    team: data.team,
    lastInviteSent: data.createdAt ? data.createdAt.toDate() : data.createdAt
  };
  return i;
};

export const queryDocumentSnapshotToMember = (doc: firebase.firestore.QueryDocumentSnapshot) => {
  let data = doc.data();
  // TODO: Why wasn't I able to use destructuring? TS error with: { id: doc.id, ...doc.data() }
  let i: MemberProps = {
    id: doc.id,
    team: data.team,
    displayName: data.displayName,
    email: data.email,
    photoURL: data.photoURL,
    createdAt: data.createdAt ? data.createdAt.toDate() : data.createdAt
  };
  return i;
};

export const queryDocumentSnapshotToTemplate = (doc: firebase.firestore.QueryDocumentSnapshot) => {
  let data = doc.data();
  // TODO: Why wasn't I able to use destructuring? TS error with: { id: doc.id, ...doc.data() }
  let q: TemplateProps = {
    id: doc.id,
    name: data.name,
    private: data.private,
    createdBy: data.createdBy
  };
  return q;
};

export const documentSnapshotToTemplate = (doc?: firebase.firestore.DocumentSnapshot) => {
  if (doc && doc.exists) {
    let t: TemplateProps = {
      id: doc.id,
      name: doc.get('name'),
      private: doc.get('private'),
      createdBy: doc.get('createdBy')
    };
    return t;
  } else {
    return null;
  }
};

export const queryDocumentSnapshotToInterview = (doc: firebase.firestore.QueryDocumentSnapshot) => {
  let data = doc.data();
  // TODO: Why wasn't I able to use destructuring? TS error with: { id: doc.id, ...doc.data() }
  let i: TInterview = {
    id: doc.id,
    userId: data.userId,
    userDisplayName: data.userDisplayName,
    candidateName: data.candidateName,
    templateId: data.templateId,
    templateName: data.templateName,
    createdDate: data.createdDate ? data.createdDate.toDate() : data.createdDate,
    createdBy: data.createdBy,
    startDate: data.startDate ? data.startDate.toDate() : data.startDate,
    notes: data.notes || ''
  };
  return i;
};

export const documentSnapshotToInterview = (doc?: firebase.firestore.DocumentSnapshot) => {
  if (doc && doc.exists) {
    const startDate = doc.get('startDate');
    const createdDate = doc.get('createdDate');

    let i: TInterview = {
      id: doc.id,
      userId: doc.get('userId'),
      userDisplayName: doc.get('userDisplayName'),
      candidateName: doc.get('candidateName'),
      templateId: doc.get('templateId'),
      templateName: doc.get('templateName'),
      createdDate: createdDate ? createdDate.toDate() : createdDate,
      createdBy: doc.get('createdBy'),
      startDate: startDate ? startDate.toDate() : startDate,
      notes: doc.get('notes') || ''
    };
    return i;
  } else {
    return null;
  }
};

export const debounce = (callback: any, time: any) => {
  let interval: any;
  return (...args: any) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};

export const showdownConverterOptions = {
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  simpleLineBreaks: true
};

export const hasReachedDemoLimit = (type: string, list: Array<any>, user: TUser) => {
  if (user.role === 'basic') {
    return false;
  }

  if (user.teamRole === 'basic') {
    return false;
  }

  if (type === 'questions') {
    if (list && list.length >= APP_PARAMS.demoQuestionsLimit) {
      return true;
    }
    return false;
  }

  if (type === 'interviews') {
    if (list && list.length >= APP_PARAMS.demoInterviewsLimit) {
      return true;
    }
    return false;
  }

  throw Error('Method called with unrecognized type');
};

export const isMemberInExpiredTeam = (user: TUser) => {
  return (user.isTeamMember && user.teamRole !== 'basic');
};

interface IDismissal {
  email: string;
  date: number;
}

export const shouldPromptForUpgrade = (user: TUser): boolean => {
  if (user.role !== 'basic' && !user.isTeamMember) {
    // Team members are never prompted. Even if the team manager is no longer 'basic'
    const dismissalList: [IDismissal] = JSON.parse(localStorage.getItem('demoDismissalDate'));

    if (!dismissalList) {
      // User is a demo account and we can't figure out when they last dismissed.
      // Most likely this is the first time they have seen this (or have cleared cache)
      return true;
    }

    const dismissal = dismissalList.find((d: IDismissal) => d.email === user.email);

    if (!dismissal) {
      // User is a demo account and while the list exists, an entry for the user does not exist
      // Possibly a shared device since the list existed, but not with their entry
      return true;
    }

    const diff = new Date().getTime() - dismissal.date;
    if (diff > 2629746000) {
      // 2629746000 = month // 604800000 = week
      // User is a demo account and must have dismissed the prompt before
      return true;
    }
  }
  return false;
};

export const setDemoDismissalDate = (email: string) => {
  let dismissalList: [IDismissal] = JSON.parse(localStorage.getItem('demoDismissalDate'));

  if (!dismissalList) {
    dismissalList = [{ email, date: new Date().getTime() }];
  } else {
    dismissalList.unshift({ email, date: new Date().getTime() });
  }

  localStorage.setItem('demoDismissalDate', JSON.stringify(dismissalList));
};

export const getValueFromQueryString = (key: string) => {
  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  const param: string = params.get(key);
  return param ? param : null;
};

export const removeValueFromQueryString = (key: string) => {
  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  params.delete(key);
};

export const precisionRound = (number: number, precision: number) => {
  if (precision < 0) {
    let factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  } else {
    return +(Math.round(Number(number + 'e+' + precision)) + 'e-' + precision);
  }
};
