import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotificationForDemo = (props: { value?: string }) => {
    const { value } = props;
    const { t } = useTranslation('translation');
    return !value ? null : (
        <div className="notification is-info">
          {value}
          <br /><br />
          <Link to="/upgrade" className="button is-success">{t('General.UpgradeNow')}</Link>
        </div>
      );
};

export default NotificationForDemo;
