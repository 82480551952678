import React from 'react';
import './style/App.sass';
import Navbar from './components/NavBar';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { UserProvider } from './stores/user/UserProvider';
import { QuestionsProvider } from './providers/QuestionsContext';
import { TemplatesProvider } from './providers/TemplatesContext';
import { useTranslation } from 'react-i18next';

import { Provider } from 'react-redux';
import { AppStore } from './stores/store';

import InterviewsPage from './pages/interviews';
import InterviewPage from './pages/interview';
import InterviewDeletePage from './pages/interviewDelete';
import { InterviewInProgressPage } from './pages/InterviewInProgress/InterviewInProgress';
import QuestionsPage from './pages/questions';
import QuestionPage from './pages/question';
import QuestionDeletePage from './pages/questionDelete';
import ProfilePage from './pages/profile';
import ProfilePasswordPage from './pages/profilePassword';
import HelpPage from './pages/help';
import ErrorPage from './pages/error';
import TermsPage from './pages/terms';
import PrivacyPage from './pages/privacy';
import SignInPage from './pages/signin';
import SignInHelpPage from './pages/signinHelp';
import SignInAuthPage from './pages/signinAuth';
import SignUpPage from './pages/signup';
import VerifyEmailPage from './pages/verifyEmail';
import TeamPage from './pages/team';
import PositionsPage from './pages/positions';
import PositionPage from './pages/position';
import PositionDeletePage from './pages/positionDelete';
import UpgradePage from './pages/upgrade';
import UpgradeSuccessPage from './pages/upgradeSuccess';
import DebugPage from './pages/debug2';

import icon from './assets/remotely-interview-mark-only.svg';
import { PrivateRoute } from './PrivateRoute';
import PublicRoute from './PublicRoute';

const App: React.FC = () => {
  const { t } = useTranslation('translation');

  const logo = { logo: icon, text: t('Menu.LogoHome'), route: '/interviews' };

  const pages = [
    {
      text: t('Menu.Questions'),
      route: '/questions'
    },
    {
      text: t('Menu.Positions'),
      route: '/positions'
    },
    {
      text: t('Menu.Interviews'),
      route: '/interviews'
    }
  ];

  const profile = { text: t('Menu.Profile'), route: '/profile' };
  const team = { text: t('Menu.Team'), route: '/team' }

  return (
    <div className="App">
      <Provider store={AppStore}>
        <Router>
          <UserProvider>
            <QuestionsProvider>
              <TemplatesProvider>
                <Navbar logo={logo} pages={pages} profile={profile} team={team} />
                <main className="bd-main">
                  <Switch>
                    <PublicRoute exact path="/terms" component={TermsPage} />
                    <PublicRoute exact path="/privacy" component={PrivacyPage} />
                    <PublicRoute exact path="/signin" component={SignInPage} />
                    <PublicRoute exact path="/signinHelp" component={SignInHelpPage} />
                    <PublicRoute exact path="/signinAuth" component={SignInAuthPage} />
                    <PublicRoute exact path="/signup" component={SignUpPage} />
                    <PrivateRoute exact path="/" component={InterviewsPage} />
                    <PrivateRoute exact path="/interviews" component={InterviewsPage} />
                    <PrivateRoute path="/interview/:id" component={InterviewPage} />
                    <PrivateRoute path="/interviewDelete/:id" component={InterviewDeletePage} />
                    <PrivateRoute path="/interviewInProgress/:id" component={InterviewInProgressPage} />
                    <PrivateRoute exact path="/questions" component={QuestionsPage} />
                    <PrivateRoute path="/question/:id" component={QuestionPage} />
                    <PrivateRoute path="/questionDelete/:id" component={QuestionDeletePage} />
                    <PrivateRoute exact path="/verifyEmail" component={VerifyEmailPage} />
                    <PrivateRoute exact path="/profile" component={ProfilePage} />
                    <PrivateRoute exact path="/profilePassword" component={ProfilePasswordPage} />
                    <PrivateRoute exact path="/help" component={HelpPage} />
                    <PrivateRoute exact path="/error" component={ErrorPage} />
                    <PrivateRoute exact path="/team" component={TeamPage} />
                    <PrivateRoute exact path="/positions" component={PositionsPage} />
                    <PrivateRoute path="/position/:id" component={PositionPage} />
                    <PrivateRoute path="/positionDelete/:id" component={PositionDeletePage} />
                    <PrivateRoute exact path="/debug" component={DebugPage} />
                    <PrivateRoute exact path="/upgrade" component={UpgradePage} />
                    <PrivateRoute exact path="/upgradeSuccess" component={UpgradeSuccessPage} />
                  </Switch>
                </main>
              </TemplatesProvider>
            </QuestionsProvider>
          </UserProvider>
        </Router>
      </Provider>
    </div>
  );
};

export default App;
