import React, { useContext, useEffect, useState } from 'react';
import { match as Imatch } from 'react-router-dom';
import { useRouteMatch, useHistory } from 'react-router';
import { deleteTemplate } from '../firebase';
import { TemplatesContext } from '../providers/TemplatesContext';
import { useTranslation } from 'react-i18next';
import Notification from '../components/Notification';
import { useSelector } from 'react-redux';
import { getUserTeam } from '../stores/user/selectors';
import { TemplateProps } from '../types';

const PositionDeletePage = () => {
  const userTeam = useSelector(getUserTeam);
  const { templates }: any = useContext(TemplatesContext);
  const [template, setTemplate] = useState<TemplateProps>();
  const { t } = useTranslation('translation');
  const match: Imatch<{ id: string }> = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (!userTeam || !templates) {
      return;
    }

    getTemplateLocal();
    async function getTemplateLocal() {
      const template = templates.find((t: { id: any }) => t.id === match.params.id);
      setTemplate(template);
    }
  }, [match.params.id, userTeam, templates]);

  if (!template) {
    return null;
  }

  return (
    <section>
      <h1 className="title is-2">{t('Position.PositionTitle')}</h1>
      <Notification value={t('Position.DeleteConfirm')} />
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">{t('Question.NameLabel')}</label>
            <div className="control is-expanded mock-input">{template.name}</div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-link"
                onClick={async (event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  deleteTemplate(userTeam, template.id);
                  history.push('/positions');
                }}
              >
                {t('General.Delete')}
              </button>
            </div>
            <div className="control">
              <button
                className="button is-text"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  history.push('/positions');
                }}
              >
                {t('General.CancelButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PositionDeletePage;
