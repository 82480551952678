import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { TemplatesContext } from '../providers/TemplatesContext';
import { cloneTemplate, addTemplate, deleteTemplate } from '../firebase';
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';
import { getUserTeam, getUser } from '../stores/user/selectors';
import { toast } from 'bulma-toast';
import Modal from "../components/Modal";
import useModal from '../useModal';

const PositionsPage = () => {
  const userTeam = useSelector(getUserTeam);
  const user = useSelector(getUser);
  const { t } = useTranslation('translation');
  const { templates, templatesLoading, templatesError }: any = useContext(TemplatesContext);
  const [searchText, setSearchText] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [fuse, setFuse] = useState<Fuse<any, any>>();
  const history = useHistory();
  const {isShowing, toggle} = useModal();

  useEffect(() => {
    if (!userTeam || !templates) {
      return;
    }

    setFuse(new Fuse(templates, { keys: ['name'] }));
  }, [userTeam, templates]);

  const handleChange = ({ target }: any) => {
    let value = target.value;
    setSearchText(value);
  };

  const deleteTemplateHandler = async (template: any) => {
    setSelectedTemplate(template);
    toggle();
  };

  const confirmDeleteHandler = async () => {
    await deleteTemplate(userTeam, selectedTemplate.id);
    toast({ message: t('Positions.PositionDeleted'), type: 'is-success', position: 'top-center', dismissible: true, pauseOnHover: true, duration: 6000, animate: { in: 'fadeIn', out: 'fadeOut' } });
    setSelectedTemplate(null);
    toggle();
  };

  const addTemplateHandler = async () => {
    const newTemplateId = await addTemplate(userTeam);
    return history.push(`/position/${newTemplateId}`);
  };

  const navigateToPosition = (event: any) => {
    let id = event.target.getAttribute('data-item');
    if (!id) {
      id = event.target.parentNode.getAttribute('data-item');
    }
    if (id) {
      history.push(`/position/${id}`);
    }
  };

  const filterTemplates = (list: any) => {
    return searchText && fuse ? fuse.search(searchText) : list;
  };

  if (templatesError) {
    return (
      <div>
        <p>
          {t('General.PageLoadError')} {templatesError}
        </p>
      </div>
    );
  }
  if (templatesLoading) {
    return (
      <div>
        <p>{t('Positions.PageLoading')}</p>
      </div>
    );
  }

  if (!templates) {
    return null;
  }

  return (
    <section>
      <h1 className="title is-2">{t('Positions.PositionsTitle')}</h1>
      <div className="columns">
        <div className="column is-three-quarters">
          <div className="field">
            <p className="control">
              <input
                className="input"
                type="text"
                placeholder={t('Positions.SearchPlaceholder')}
                onChange={handleChange}
                value={searchText}
              />
            </p>
          </div>
        </div>
        <div className="column">
          <button className="button is-success is-fullwidth" onClick={addTemplateHandler}>
            {t('Positions.NewPositionButton')}
          </button>
        </div>
      </div>

      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>{t('Positions.PositionsHeaderName')}</th>
            <th>{t('Positions.PositionsHeaderActions')}</th>
          </tr>
        </thead>
        <tbody>
          {filterTemplates(templates).map((v: any) => (
            <tr key={v.id} data-item={v.id} onClick={navigateToPosition}>
              <td>{v.name}</td>
              <td>
                <div className="field is-grouped buttons are-small">
                  <p className="control">
                    <Link
                      className="button is-success"
                      to={{
                        pathname: `/position/${v.id}`,
                        state: {
                          template: v
                        }
                      }}
                    >
                      {t('General.Edit')}
                    </Link>
                  </p>
                  <p className="control">
                    <button
                      className="button is-info"
                      onClick={async (event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        const newTemplateId = await cloneTemplate(userTeam, v.id);
                        history.push(`/position/${newTemplateId}`);
                      }}
                    >
                      {t('General.Clone')}
                    </button>
                  </p>
                  <p className={'control ' + (user.isTeamMember ? 'is-hidden' : '')}>
                  <button className="button is-danger" onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        deleteTemplateHandler(v);
                      }}>{t('General.Delete')}</button>
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal isShowing={isShowing} title={t('General.ConfirmTitle')} hide={toggle} confirm={confirmDeleteHandler} >
        <div>
          <div>{t('Position.DeleteConfirm')}</div>
          <div>{ selectedTemplate ? selectedTemplate.name : '' }</div>
        </div>
      </Modal>
    </section>
  );
};

export default PositionsPage;
