import { Placement } from 'react-joyride';

export const getJoyrideSteps = (t: any) => {
  return [
    {
      disableBeacon: true,
      placement: 'center' as Placement,

      target: 'body',
      content: t('QuestionsTour.Intro')
    },
    {
      placement: 'left' as Placement,

      target: '[data-cy=new-question-button]',
      content: t('QuestionsTour.AddQuestion')
    },
    {
      target: '#questions-table-header',
      content: t('QuestionsTour.QuestionsTable')
    },
    {
      target: '#page-link-positions',
      content: t('QuestionsTour.PositionsLink')
    },
    {
      target: '#page-link-interviews',
      content: t('QuestionsTour.InterviewsLink')
    }
  ];
};

export const getJoyrideLocale = (t: any) => {
  return {
    last: t('QuestionsTour.EndTour'),
    skip: t('QuestionsTour.CloseTour'),
    next: t('QuestionsTour.Next'),
    back: t('QuestionsTour.Back')
  };
};

// TODO: Need something more permanent than localStorage. Consider user profile
export const joyrideRunCheck = (user: any) => {
  if (!user.isTourTaken) {
    return true;
  }
  return false;
};

export const defaultStyles = {
  options: {
    primaryColor: '#01579b',
    textColor: '#363636'
  }
};
