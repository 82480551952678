import React, { useEffect, useState } from 'react';
import { match as Imatch } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { InterviewQuestion } from './interviewQuestion';
import { loadInterview, handleInterviewChange } from './store/actions';
import { getInterview, getQuestions } from './store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import InterviewSums from '../../components/InterviewSums';

export const InterviewInProgressPage = () => {
  const match: Imatch<{ id: string }> = useRouteMatch();
  const interviewId = match.params.id;
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const interview = useSelector(getInterview);
  const questions = useSelector(getQuestions);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [lastModifiedDate, setLastModifiedDate] = useState(new Date());

  // This will load our Interview once all data is in place
  useEffect(() => {
    dispatch(loadInterview(interviewId));
    
    return () => {
      dispatch({
        type: 'INTERVIEW_IN_PROGRESS_UNLOAD'
      });
    };
  }, [interviewId, dispatch]);

  const handleChange = ({ target }: any) => {
    let { name, value } = target;
    dispatch(handleInterviewChange({ ...interview, [name]: value }));
  };

  if (!interview.id) {
    return (
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="box">
                <h4 className="title is-4 has-text-centered">{t('Interviews.PageLoading')}</h4>
                <h5 className="title is-5 has-text-centered">{t('Upgrade.ThanksForPatience')}</h5>
                <div className="has-text-centered">
                  <progress className="progress is-small is-primary" max="100">
                    15%
                  </progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section>
      <div className="columns">
        <div className="column is-three-quarters">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <a href="#test">{interview.candidateName}</a>
              </li>
              <li>
                <a href="#test">{interview.templateName}</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="column is-one-quarter">
          <InterviewSums questions={questions}></InterviewSums>
        </div>
      </div>

      <div className="columns">
        <div className="column is-three-quarters">
          {questions.map((question: any, index: number) => (
            <InterviewQuestion
              index={index}
              key={question.id}
              questionId={question.id}
              selectedQuestionId={selectedQuestionId}
              setSelectedQuestionId={setSelectedQuestionId}
              lastModifiedDate={lastModifiedDate}
              setLastModifiedDate={setLastModifiedDate}
            />
          ))}
        </div>
        <div className="column is-one-quarter">
          <textarea
            className="textarea"
            name="notes"
            placeholder={t('InterviewInProgress.NotesLabel')}
            value={interview.notes}
            onChange={handleChange}
            style={{ height: '100%', maxHeight: '100%', boxSizing: 'border-box' }}
          ></textarea>
        </div>
      </div>
    </section>
  );
};
