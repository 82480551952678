import React from 'react';
import { useTranslation } from 'react-i18next';

const ProfileInitials = (props: { user: any }) => {
  const { user } = props;
  const { t } = useTranslation('translation');

  function renderPhoto() {
    return (
      <p className="image is-32x32">
        <img className="is-rounded" alt={t('Team.ProfilePictureAlt') + ' ' + user.displayName} src={user.photoURL} />
      </p>
    );
  }

  return (
    <>
      {(!user || !user.photoURL) && null}
      {user && user.photoURL && renderPhoto()}
    </>
  );
};

export default ProfileInitials;
