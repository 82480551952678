import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionsContext } from '../providers/QuestionsContext';
import { cloneQuestion, removeQuestion, updateUserTourTaken } from '../firebase';
import { useTranslation } from 'react-i18next';
import { arrayToCsv, getValueFromQueryString, hasReachedDemoLimit, isMemberInExpiredTeam } from '../utilities';
import Fuse from 'fuse.js';
import NotificationForDemo from '../components/NotificationForDemo';
import Notification from '../components/Notification';
import { useSelector } from 'react-redux';
import { getUserTeam, getUser } from '../stores/user/selectors';
import { toast } from 'bulma-toast';
import Modal from '../components/Modal';
import useModal from '../useModal';
import { defaultStyles, getJoyrideLocale, getJoyrideSteps, joyrideRunCheck } from '../joyride';
import ReactJoyride from 'react-joyride';

const fuseOptions = { keys: ['name', 'tags'] };

const QuestionsPage = () => {
  const { t } = useTranslation('translation');
  const userTeam = useSelector(getUserTeam);
  const user = useSelector(getUser);
  const { questions, loading, error }: any = useContext(QuestionsContext);
  const [searchText, setSearchText] = useState('');
  const [questionSaved, setQuestionSaved] = useState('');
  const [demoLimitReached, setDemoLimitReached] = useState(false);
  const [runJoyride, setRunJoyride] = useState(joyrideRunCheck(user));
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [fuse, setFuse] = useState<Fuse<any, any>>();
  const history = useHistory();
  const { isShowing, toggle } = useModal();

  const steps = getJoyrideSteps(t);
  const locale = getJoyrideLocale(t);

  useEffect(() => {
    if (!userTeam || !questions) {
      return;
    }

    if (!questionSaved) {
      const id = getValueFromQueryString('questionSaved');
      if (id) {
        toast({
          message: t('Questions.QuestionSaved'),
          type: 'is-success',
          position: 'top-center',
          dismissible: true,
          pauseOnHover: true,
          duration: 6000,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
        setQuestionSaved(id);
        history.push('/questions');
      }
    }

    setDemoLimitReached(hasReachedDemoLimit('questions', questions, user));
    setFuse(new Fuse(questions, fuseOptions));
  }, [user, userTeam, questions, questionSaved, history, t]);

  const handleChange = ({ target }: any) => {
    let value = target.value;
    setSearchText(value);
  };

  const deleteQuestionHandler = async (question: any) => {
    setSelectedQuestion(question);
    toggle();
  };

  const confirmDeleteHandler = async () => {
    await removeQuestion(selectedQuestion.id, userTeam);
    toast({
      message: t('Questions.QuestionDeleted'),
      type: 'is-success',
      position: 'top-center',
      dismissible: true,
      pauseOnHover: true,
      duration: 6000,
      animate: { in: 'fadeIn', out: 'fadeOut' }
    });
    setSelectedQuestion(null);
    toggle();
  };

  const cloneQuestionHandler = async (id: string) => {
    const newQuestionId = await cloneQuestion(userTeam, id);
    return history.push(`/question/${newQuestionId}`);
  };

  const addQuestionHandler = async () => {
    return history.push(`/question/0`);
  };

  const navigateToQuestion = (event: any) => {
    let id = event.target.getAttribute('data-item');
    if (!id) {
      id = event.target.parentNode.getAttribute('data-item');
    }
    if (id) {
      history.push(`/question/${id}`);
    }
  };

  const filterQuestions = (list: any) => {
    return searchText && fuse ? fuse.search(searchText) : list;
  };

  if (error) {
    return (
      <div>
        <p>
          {t('General.PageLoadError')} {error}
        </p>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <p>{t('Questions.PageLoading')}</p>
      </div>
    );
  }
  if (!questions) {
    return null;
  }

  return (
    <section>
      {demoLimitReached && isMemberInExpiredTeam(user) && <Notification value={t('General.ExpiredTeam')} />}
      {demoLimitReached && !isMemberInExpiredTeam(user) && <NotificationForDemo value={t('Questions.QuestionsLimitReached')} />}
      <h1 className="title is-2">{t('Questions.QuestionsTitle')}</h1>
      <div className="columns">
        <div className="column is-three-quarters">
          <div className="field">
            <p className="control">
              <input
                className="input"
                type="text"
                placeholder={t('Questions.SearchPlaceholder')}
                onChange={handleChange}
                value={searchText}
              />
            </p>
          </div>
        </div>
        <div className="column">
          <button
            disabled={demoLimitReached}
            className="button is-success is-fullwidth"
            onClick={addQuestionHandler}
            data-cy="new-question-button"
          >
            {t('Questions.NewQuestionButton')}
          </button>
        </div>
      </div>

      <table className="table is-hoverable is-fullwidth">
        <thead id="questions-table-header">
          <tr>
            <th>{t('Questions.QuestionsHeaderName')}</th>
            <th>{t('Questions.QuestionsHeaderTags')}</th>
            <th>{t('Questions.QuestionsHeaderActions')}</th>
          </tr>
        </thead>
        <tbody>
          {filterQuestions(questions).map((q: any) => (
            <tr key={q.id} data-item={q.id} onClick={navigateToQuestion}>
              <td>{q.name}</td>
              <td>{Array.isArray(q.tags) ? arrayToCsv(q.tags) : q.tags}</td>
              <td>
                <div className="field is-grouped buttons are-small">
                  <p className="control">
                    <Link className="button is-success" to={{ pathname: `/question/${q.id}` }}>
                      {t('General.Edit')}
                    </Link>
                  </p>
                  <p className="control">
                    <button
                      disabled={demoLimitReached}
                      className="button is-info"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        cloneQuestionHandler(q.id);
                      }}
                    >
                      {t('General.Clone')}
                    </button>
                  </p>
                  <p className={'control ' + (user.isTeamMember ? 'is-hidden' : '')}>
                    <button
                      className="button is-danger"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        deleteQuestionHandler(q);
                      }}
                    >
                      {t('General.Delete')}
                    </button>
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactJoyride
        steps={steps}
        locale={locale}
        continuous={true}
        showSkipButton={true}
        run={runJoyride}
        callback={e => {
          if (e.type === 'tour:end' || e.action === 'close') {
            window.scrollTo(0, 0);
            setRunJoyride(false);
            user.isTourTaken = true;
            updateUserTourTaken(user.uid);
            // This will end up causing the effect to run. This is fine due to no api calls in effect
          }
        }}
        styles={defaultStyles}
      ></ReactJoyride>
      <Modal isShowing={isShowing} title={t('General.ConfirmTitle')} hide={toggle} confirm={confirmDeleteHandler}>
        <div>
          <div>{t('Question.DeleteConfirm')}</div>
          <div>{selectedQuestion ? selectedQuestion.name : ''}</div>
        </div>
      </Modal>
    </section>
  );
};

export default QuestionsPage;
