import React from 'react';
import ProfileInitials from './ProfileInitials';
import ProfileImage from './ProfileImage';

const ProfileCard = (props: { user: any; isCompact?: boolean; hasBackgroundDark?: boolean }) => {
  const { user, isCompact, hasBackgroundDark } = props;

  const CardLayout = (props: { content: any }) => {
    const { content } = props;

    return (
      <article className="media">
        <figure className="media-left">{content}</figure>
        <div className="media-content">
          <div className="content">
            <p className="control">{user.displayName}</p>
          </div>
        </div>
      </article>
    );
  };

  // TODO: There has got to be a better "React" way to organize this...
  if (!user) {
    return null;
  } else if (user.photoURL) {
    if (isCompact) {
      return <ProfileImage user={user}></ProfileImage>;
    } else {
      return <CardLayout content={<ProfileImage user={user}></ProfileImage>}></CardLayout>;
    }
  } else if (!user.photoURL) {
    if (isCompact) {
      return <ProfileInitials user={user} hasBackgroundDark={hasBackgroundDark}></ProfileInitials>;
    } else {
      return (
        <CardLayout
          content={<ProfileInitials user={user} hasBackgroundDark={hasBackgroundDark}></ProfileInitials>}
        ></CardLayout>
      );
    }
  }

  return null;
};

export default ProfileCard;
