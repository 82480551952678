import React, { useEffect, useState, useContext, useRef } from 'react';
import { match as Imatch } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router';
import { saveQuestionChangeToTemplate, getQuestion, saveQuestion } from '../firebase';
import {
  arrayToTags,
  tagsToArray,
  showdownConverterOptions,
  hasReachedDemoLimit,
  getValueFromQueryString,
  getTagsFromQuestions,
  isMemberInExpiredTeam
} from '../utilities';
import { useTranslation } from 'react-i18next';
import { QuestionsContext } from '../providers/QuestionsContext';
import { TemplatesContext } from '../providers/TemplatesContext';
import ReactTags from 'react-tag-autocomplete';
import { useSelector } from 'react-redux';
import { getUserTeam, getUser } from '../stores/user/selectors';
import { QuestionProps, Tag } from '../types';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import NotificationForDemo from '../components/NotificationForDemo';
import Notification from '../components/Notification';
import MenuButton from '../components/MenuButton';
import AddPosition from '../components/AddPosition';

const converter = new Showdown.Converter(showdownConverterOptions);

const QuestionPage = () => {
  const userTeam = useSelector(getUserTeam);
  const user = useSelector(getUser);
  const { questions }: any = useContext(QuestionsContext);
  const { templates /*, templatesLoading, templatesError*/ }: any = useContext(TemplatesContext);
  const [question, setQuestion] = useState<QuestionProps>();
  const [tags, setTags] = useState<Tag[]>();
  const [suggestedTags, setSuggestedTags] = useState<Tag[]>();
  // const [templates, setTemplates] = useState<TemplateProps[]>();
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>();
  const [questionMdeSelectedTab, setQuestionMdeSelectedTab] = React.useState<'write' | 'preview'>('write');
  const [answerMdeSelectedTab, setAnswerMdeSelectedTab] = React.useState<'write' | 'preview'>('write');
  const [demoLimitReached, setDemoLimitReached] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const { t } = useTranslation('translation');
  const history = useHistory();
  const match: Imatch<{ id: string }> = useRouteMatch();
  const menuButtonRef = useRef(null);

  useEffect(() => {
    if (!userTeam) {
      return;
    }

    getQuestionLocal();
    async function getQuestionLocal() {
      let [question] = await Promise.all([getQuestion(userTeam, match.params.id)]);

      // Only check demo limit if this is a new question
      if (match.params.id === '0') {
        const position = getValueFromQueryString('position');
        if (position) {
          question.templates.push(position);
        }

        setSelectedTemplates([]);
      } else {
        setSelectedTemplates(question.templates);
      }

      setQuestion(question);
      setTags(arrayToTags(question.tags));
    }
  }, [match.params.id, user, userTeam]);

  useEffect(() => {
    if (!userTeam || !questions) {
      return;
    }

    getLocal();
    async function getLocal() {
      // Only check demo limit if this is a new question
      if (match.params.id === '0') {
        setDemoLimitReached(hasReachedDemoLimit('questions', questions, user));
      }
      const tags = getTagsFromQuestions(questions);
      setSuggestedTags(tags);
    }
  }, [match.params.id, user, userTeam, questions]);

  const handleChange = ({ target }: any) => {
    let { name, type } = target;
    let value = type === 'checkbox' ? target.checked : target.value;

    setQuestion({ ...question, [name]: value });
  };

  const handleQuestionMceChange = (value: any) => {
    setQuestion({ ...question, question: value });
  };

  const handleAnswerMceChange = (value: any) => {
    setQuestion({ ...question, answer: value });
  };

  const handleMultiSelectChange = (e: any) => {
    let value = Array.from(e.target.selectedOptions, (option: any) => option.value);
    setQuestion({ ...question, templates: value });
  };

  const handleTagDelete = (...args: any[]) => {
    let newTags = tags.slice(0);
    newTags.splice(args[0], 1);
    setTags(newTags);
  };

  const handleTagAddition = (tag: any) => {
    setTags([].concat(tags, tag));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!question.name) {
      return;
    }

    setIsButtonLoading(true);
    question.tags = tagsToArray(tags);
    question.templates = question.templates || [];

    const id = await saveQuestion(userTeam, question);
    await saveTemplateChanges();
    setSelectedTemplates(question.templates);
    setIsButtonLoading(false);
    redirectToParent(id);
  };

  const saveTemplateChanges = async () => {
    const removed = selectedTemplates.filter((t: string) => !question.templates.includes(t));
    const added = question.templates.filter((t: string) => !selectedTemplates.includes(t));
    const actions: any[] = [];

    removed.forEach(async (r: string) => {
      actions.push(saveQuestionChangeToTemplate(r, false, userTeam, question));
    });

    added.forEach(async (a: string) => {
      actions.push(saveQuestionChangeToTemplate(a, true, userTeam, question));
    });

    await Promise.all(actions);
  };

  const cancelEdit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    redirectToParent(null);
  };

  const handlePositionSave = async () => {
    menuButtonRef.current.closeMenu();
  };

  const handlePositionCancel = () => {
    menuButtonRef.current.closeMenu();
  };

  const redirectToParent = (id: string) => {
    const position = getValueFromQueryString('position');

    if (position) {
      history.push(`/position/${position}`);
    } else {
      history.push((id) ? `/questions?questionSaved=${id}` : `/questions`);
    }
  };

  if (!question || !templates) {
    return null; // TODO: Actually would be better to show "loading"...
  }

  if (demoLimitReached) {
    if (isMemberInExpiredTeam(user)) {
      return <Notification isError={false} value={t('General.ExpiredTeam')} />;
    } else {
      return <NotificationForDemo value={t('Questions.QuestionsLimitReached')} />;
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <h1 className="title is-2">{t('Question.QuestionTitle')}</h1>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label className="label">{t('Question.NameLabel')}</label>
              <div className="control is-expanded">
                <input className="input" type="text" name="name" value={question.name} onChange={handleChange} />
              </div>
            </div>

            <div className="field">
              <label className="label">{t('Question.TagsLabel')}</label>
              <div className="control is-expanded">
                <ReactTags
                  tags={tags}
                  suggestions={suggestedTags}
                  onAddition={handleTagAddition}
                  onDelete={handleTagDelete}
                  allowNew={true}
                  delimiters={[',', ';', 'Enter', 'Tab']}
                  placeholderText={''}
                  minQueryLength={1}
                ></ReactTags>
              </div>
            </div>

            <div className="field">
              <label className="label is-pulled-left">
                <div>{t('Question.PositionLabel')}</div>
              </label>
            </div>
            <div className="field">
              <div className="is-pulled-right">
                <MenuButton content={t('General.AddPosition')} ref={menuButtonRef}>
                  <AddPosition
                    team={userTeam}
                    onCancel={handlePositionCancel}
                    onSave={handlePositionSave}
                  ></AddPosition>
                </MenuButton>
              </div>
            </div>
            <div className="field">
              <div className="control select is-fullwidth is-multiple">
                <select name="templates" value={question.templates} onChange={handleMultiSelectChange} multiple>
                  {templates.map((t: any) => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="field">
              <label className="label is-pulled-left">{t('Question.QuestionLabel')}</label>
            </div>
            <div className="field">
              <div className="is-pulled-right">
                {/* <MenuButton content={'Formatting Help'}>
                  <article className="message is-info" style={{ minWidth: '300px' }}>
                    <div className="message-header">
                      <p>Info</p>
                      <button className="delete" aria-label="delete"></button>
                    </div>
                    <div className="message-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Pellentesque risus mi</strong>,
                      tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla. Nullam
                      gravida purus diam, et dictum <a>felis venenatis</a> efficitur. Aenean ac <em>eleifend lacus</em>,
                      in mollis lectus. Donec sodales, arcu et sollicitudin porttitor, tortor urna tempor ligula, id
                      porttitor mi magna a neque. Donec dui urna, vehicula et sem eget, facilisis sodales sem.
                    </div>
                  </article>
                </MenuButton> */}
              </div>
            </div>
            <div className="field">
              <div className="control is-expanded">
                <ReactMde
                  classes={{ textArea: 'question-edit-textarea' }}
                  value={question.question}
                  onChange={handleQuestionMceChange}
                  selectedTab={questionMdeSelectedTab}
                  onTabChange={setQuestionMdeSelectedTab}
                  generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">{t('Question.AnswerLabel')}</label>
              <div className="control is-expanded">
                <ReactMde
                  classes={{ textArea: 'answer-edit-textarea' }}
                  value={question.answer}
                  onChange={handleAnswerMceChange}
                  selectedTab={answerMdeSelectedTab}
                  onTabChange={setAnswerMdeSelectedTab}
                  generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
                />
              </div>
            </div>

            <div className="field is-grouped">
              <div className="control">
              <button type="submit" className={`button is-link ${isButtonLoading ? 'is-loading' : ''}`} data-cy="questionSave" >
                  {t('General.SaveButton')}
                </button>
              </div>
              <div className="control">
                <button className="button is-text" onClick={cancelEdit}>
                  {t('General.CancelButton')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};

export default QuestionPage;
