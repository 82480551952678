import { combineReducers } from 'redux';
import {
  reducer as InterviewInProgressReducer,
  mountPoint as InterviewInProgressMountPoint
} from '../pages/InterviewInProgress/store/reducer';

import { reducer as userReducer, mountPoint as userMountPoint } from './user/reducer';

export const rootReducer = combineReducers({
  [InterviewInProgressMountPoint]: InterviewInProgressReducer,
  [userMountPoint]: userReducer
});

export type AppState = ReturnType<typeof rootReducer>;
