import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from './stores/user/selectors';
import { shouldPromptForUpgrade } from './utilities';

export const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const user = useSelector(getUser);

  if (!Component) {
    throw Error('component is undefined');
  }

  // For now have decided that only the manual username/password users need to verify
  const shouldVerifyEmail = (user: any) => {
    return (user.providerId === 'password' && !user.emailVerified);
  };

  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    // initial state for user is false... so this line prevents the "flash of login"
    if (!user) {
      return null;
    }

    // The user just signed up but email isn't verified... force them to only see verification page
    if (user.team && shouldVerifyEmail(user) && props.location.pathname !== '/verifyEmail') {
      return <Redirect to={{ pathname: '/verifyEmail' }} />;
    }

    // The user just signed up but email isn't verified... force them to only see verification page
    if (user.inactiveMember && props.location.pathname !== '/error') {
      return <Redirect to={{ pathname: '/error', search: "?code=inactive" }} />;
    }

    // Present user with Demo page
    // TODO: If user is already upgraded don't let them go here... OR let them and handle on page
    if (
      props.location.pathname !== '/verifyEmail' &&
      props.location.pathname !== '/upgrade' &&
      shouldPromptForUpgrade(user)
    ) {
      return <Redirect to={{ pathname: '/upgrade' }} />;
    }

    // The user is logged in and verified and can now view component behind this private route
    if (user.team) {
      return <Component {...props} />;
    }

    //
    return <Redirect to={{ pathname: '/signin' }} />;
  };

  return <Route {...rest} render={render} />;
};

//TODO: Was having issues with TS and examples
//https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs
//https://stackoverflow.com/questions/49639761/write-privateroute-component-in-a-typescript-friendly-way
