export const mountPoint = 'authUser';

export type TUser = {
  createdAt: string;
  displayName: string;
  email: string;
  photoURL: string;
  team: string;
  uid: string;
  emailVerified: boolean;
  language?: string;
  role: string;
  teamRole: string;
  isTeamMember: boolean;
  inactiveMember: boolean;
  providerId?: string;
  isTourTaken: boolean;
};

const initialState: TUser | boolean = false;

export const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_AUTH_USER':
      return { ...payload };

    default:
      return state;
  }
};
