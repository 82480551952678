import { mountPoint, InterviewInProgressState } from './reducer';
import { AppState } from '../../../stores/root-reducer';
import { TInterview, TInterviewQuestion } from '../../../types';

const getStateSlice = (state: AppState): InterviewInProgressState => state[mountPoint];

export const getInterview = (state: AppState): TInterview => {
  return getStateSlice(state).interview;
};

export const getQuestions = (state: AppState): TInterviewQuestion[] => {
  return getStateSlice(state).questions;
};

export const getQuestion = (id: string) => (state: AppState): TInterviewQuestion => {
  return getStateSlice(state).questions.find((q: TInterviewQuestion) => q.id === id);
};
