import { useEffect } from 'react';
import { auth, createUserProfileDocument, /*, firebaseConfigApiKey*/ 
getTeamClaims,
isInactive} from '../../firebase';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../userLanguage';

export const UserProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation('translation');

  useEffect(() => {
    return auth.onAuthStateChanged(async (userAuth: any) => {
      // userAuth will exist when the user is logged in otherwise it is null
      if (userAuth) {
        // this method will ensure the user has a team and user document
        const userRef = await createUserProfileDocument(userAuth);
        const emailVerified = auth.currentUser.emailVerified;

        await auth.currentUser.getIdToken(true);
        const decodedToken = await auth.currentUser.getIdTokenResult();
        const role = decodedToken.claims.stripeRole;
        const providerId = getUserProviderId();

        userRef.onSnapshot(async snapshot => {
          const snapshotData = snapshot.data();

          if (snapshotData) {
            const isTeamMember = snapshotData.team !== snapshot.id;
            let teamRole = null;
            let inactiveMember = false;
            if (isTeamMember) {
              [teamRole, inactiveMember] = await Promise.all([
                getTeamClaims(snapshotData.team),
                isInactive(snapshotData.team, snapshot.id)
              ]);
            }

            if (snapshotData.language) {
              const lang = getLanguage(snapshotData.language);
              i18n.changeLanguage(lang);
            }

            dispatch({
              type: 'SET_AUTH_USER',
              payload: { uid: snapshot.id, role, teamRole, inactiveMember, providerId, emailVerified, isTeamMember, ...snapshotData }
            });
          }
        });
      } else {
        i18n.changeLanguage(getLanguage());
        dispatch({
          type: 'SET_AUTH_USER',
          payload: userAuth
        });
      }
    });
  }, [dispatch, i18n]);

  return children;
};

const getUserProviderId = () => {
  if (auth && auth.currentUser && auth.currentUser.providerData && auth.currentUser.providerData.length > 0) {
    return auth.currentUser.providerData[0].providerId;
  }
  return null;
};
