import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

const Modal = (props) => {
  const { t } = useTranslation('translation');
  const { isShowing, hide, children, title, confirm } = props;

  return isShowing
  ? ReactDOM.createPortal(
      <React.Fragment>
        <div className="modal-overlay" />
        <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
          <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" onClick={hide} />
              </header>
              <section className="modal-card-body">
                <div className="content">
                  {children}
                </div>
              </section>
              <footer className="modal-card-foot">
                <div className="field is-grouped">
                  <div className="control">
                    <button type="submit" className="button is-link" onClick={confirm} data-cy="modalConfirm">
                      {t('General.ConfirmButton')}
                    </button>
                  </div>
                  <div className="control">
                    <button className="button is-text" onClick={hide}>
                      {t('General.CancelButton')}
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    )
  : null;

};

export default Modal;