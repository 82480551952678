import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth, signInWithGoogle, signInWithTwitter, signInWithFacebook /*, signInWithMicrosoft*/ } from '../firebase';
import useForm from '../useForm';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faGoogle, faTwitter, faFacebook /*, faMicrosoft */ } from '@fortawesome/free-brands-svg-icons';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import logo from '../assets/remotely-logo-04-cropped.png';
import PublicLanguageChanger from '../components/PublicLanguageChanger';
import i18n from '../i18n';

const SignInPage: React.FC = () => {
  const user = useSelector(getUser);
  const { t } = useTranslation('translation');

  const signIn = async () => {
    try {
      setError('');
      await auth.signInWithEmailAndPassword(values.email, values.password);
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const signInWithProvider = async (func: any) => {
    try {
      setError('');
      await func();
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const { values, handleChange, handleSubmit }: any = useForm(signIn);
  const [error, setError] = useState('');

  if (user.team) {
    return <Redirect to={{ pathname: '/questions' }} />;
  } else {
    return (
      <>
        <div className="level signin-logo">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                <img src={logo} className="App-logo" alt="Remotely Interview Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="level signin">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                <p className="title">{t('SignIn.Title')}</p>
                <p className="subtitle">{t('SignIn.SubTitle')}</p>
                <div className="signin-form">
                  <form onSubmit={handleSubmit}>
                    <div className="field">
                      <label className="label">{t('SignIn.EmailLabel')}</label>
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          placeholder={t('SignIn.EmailPlaceholder')}
                          name="email"
                          value={values.email || ''}
                          onChange={handleChange}
                          data-cy="username"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">{t('SignIn.PasswordLabel')}</label>
                      <div className="control">
                        <input
                          className="input"
                          type="password"
                          placeholder={t('SignIn.PasswordPlaceholder')}
                          name="password"
                          value={values.password || ''}
                          onChange={handleChange}
                          data-cy="password"
                        />
                        <div className="is-fullwidth has-text-right help">
                          <Link to={`/signinHelp?lang=${i18n.language}`}>{t('SignIn.ForgotPassword')}</Link>
                        </div>
                      </div>
                    </div>

                    {error && <div className="notification is-danger">{error}</div>}

                    <button className="button is-fullwidth is-primary" type="submit" data-cy="loginButton">
                      {t('SignIn.SignInButton')}
                    </button>
                  </form>
                </div>
                <h6 className="is-6 has-text-centered signin-spacer">{t('SignIn.Or')}</h6>
                <div className="has-text-centered social-signin">
                  <button
                    className="button is-google is-fullwidth"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.preventDefault();
                      signInWithProvider(signInWithGoogle);
                    }}
                  >
                    <Icon icon={faGoogle} />
                    &nbsp;&nbsp;
                    <span>{t('SignIn.SignInWithGoogle')}</span>
                  </button>
                </div>
                <div className="has-text-centered social-signin">
                  <button
                    className="button is-facebook is-fullwidth"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.preventDefault();
                      signInWithProvider(signInWithFacebook);
                    }}
                  >
                    <Icon icon={faFacebook} />
                    &nbsp;&nbsp;
                    <span>{t('SignUp.SignInWithFacebook')}</span>
                  </button>
                </div>
                <div className="has-text-centered social-signin">
                  <button
                    className="button is-twitter is-fullwidth"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.preventDefault();
                      signInWithProvider(signInWithTwitter);
                    }}
                  >
                    <Icon icon={faTwitter} />
                    &nbsp;&nbsp;
                    <span>{t('SignUp.SignInWithTwitter')}</span>
                  </button>
                </div>
                {/* <div className="has-text-centered social-signin">
                  <button
                    className="button is-microsoft is-fullwidth"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.preventDefault();
                      signInWithProvider(signInWithMicrosoft);
                    }}
                  >
                    <Icon icon={faMicrosoft} />
                    &nbsp;&nbsp;
                    <span>{t('SignUp.SignInWithMicrosoft')}</span>
                  </button>
                </div> */}
              </div>
              <Link to={`/signup?lang=${i18n.language}`}>
                <footer className="card-footer">
                  <p className="card-footer-item register">
                    <span>{t('SignIn.SignUpButton')}</span>
                  </p>
                </footer>
              </Link>
            </div>
          </div>
        </div>

        <PublicLanguageChanger></PublicLanguageChanger>
      </>
    );
  }
};

export default SignInPage;
