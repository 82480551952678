import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import { auth } from '../firebase';
import { Link } from 'react-router-dom';

const VerifyEmailPage = () => {
  const user = useSelector(getUser);
  const [profile, setProfile] = useState<any>();
  const [sent, setSent] = useState<boolean>(false);
  //   const [saved, setSaved] = useState<boolean>(false);
  const { t, i18n } = useTranslation('translation');

  useEffect(() => {
    if (!user || !user.team) {
      return;
    }

    setProfile({ ...user });
  }, [user]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    auth.languageCode = i18n.language;
    await auth.currentUser.sendEmailVerification();
    setSent(true);
  };

  const handleTryAgain = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setSent(false);
  };

  function renderEmailAlreadyVerified() {
    return (
      <>
        <p className="title has-text-centered">{t('VerifyEmail.Title')}</p>
        <p className="subtitle has-text-centered"> </p>
        <p className="verify-email-content">
          {t('VerifyEmail.Greeting')} {profile.displayName || ''}
        </p>
        <p className="verify-email-content">{t('VerifyEmail.AlreadyVerified')}</p>

        <div className="has-text-centered verify-email-content">
          <Link to="/questions">{t('VerifyEmail.BackToApp')}</Link>
        </div>
      </>
    );
  }

  function renderVerifyEmailLanding() {
    return (
      <>
        <p className="title has-text-centered">{t('VerifyEmail.Title')}</p>
        <p className="subtitle has-text-centered"> </p>
        <p className="verify-email-content">
          {t('VerifyEmail.Greeting')} {profile.displayName || ''}
        </p>
        <p className="verify-email-content">{t('VerifyEmail.MainMessage')}</p>

        <div className="has-text-centered verify-email-content">
          <form onSubmit={handleSubmit}>
            <button type="submit" className="button is-link">
              {t('VerifyEmail.SendButton')}
            </button>
          </form>
        </div>
      </>
    );
  }

  function renderVerifyEmailSent() {
    return (
      <>
        <p className="title has-text-centered">{t('VerifyEmail.SentTitle')}</p>
        <p className="subtitle has-text-centered"> </p>
        <p className="verify-email-content">{t('VerifyEmail.SentMessage')}</p>
        <p className="verify-email-content">{t('VerifyEmail.SentSubMessage')}</p>
        <p className="has-text-centered verify-email-content">
          <button className="button is-text" onClick={handleTryAgain}>
            {t('VerifyEmail.TryAgainButton')}
          </button>
        </p>
      </>
    );
  }

  if (!profile) {
    return null;
  }

  return (
    <section>
      <div className="level signin">
        <div className="level-item">
          <div className="card">
            <div className="card-content has-text-centered verify-email">
              {!sent && user.emailVerified && renderEmailAlreadyVerified()}
              {!sent && !user.emailVerified && renderVerifyEmailLanding()}
              {sent && renderVerifyEmailSent()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmailPage;
