export const getLanguage = (lang?: string): string => {
  if (lang) {
    return lang;
  }

  const queryString = getLanguageFromQueryString();
  if (queryString) {
    return queryString;
  }

  const browserLang = getLanguageFromBrowser();
  if (browserLang) {
    return browserLang;
  }

  return null;
};

export const getLanguageFromQueryString = () => {
  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  const param: string = params.get('lang');
  return param ? param : null;
};

export const getLanguageFromBrowser = () => {
  var [lang] = window.navigator['language']
    .replace('-', '_')
    .toLowerCase()
    .split('_');

  return lang;
};
