import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase';
import useForm from '../useForm';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import logo from '../assets/remotely-logo-04-cropped.png';
import PublicLanguageChanger from '../components/PublicLanguageChanger';

const SignInPasswordResetPage: React.FC = () => {
  const userTeam = useSelector(getUser);
  const { t, i18n } = useTranslation('translation');
  const [loading, setLoading] = useState(true);
  const [verifyPasswordError, setVerifyPasswordError] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState('');
  const [resetPasswordCode, setResetPasswordCode] = useState('');

  function getQueryParam(param: string) {
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    return params.get(param);
  }

  const mode = getQueryParam('mode');

  useEffect(() => {
    if (!userTeam) {
      return;
    }

    getAuthInfo();
    async function getAuthInfo() {
      // TODO: Consider redirect... this page is getting cluttered
      if (mode === 'verifyEmail') {
        try {
          await auth.applyActionCode(getQueryParam('oobCode'));
        } catch (error) {
          setVerifyPasswordError(t(`ErrorCodes.${error.code}`));
        } finally {
          setLoading(false);
        }
      } else if (mode === 'resetPassword') {
        try {
          await auth.verifyPasswordResetCode(getQueryParam('oobCode'));
        } catch (error) {
          setVerifyPasswordError(t(`ErrorCodes.${error.code}`));
        } finally {
          setLoading(false);
        }
      } else {
        // Mode wasn't recognized. Custom error
        // setVerifyPasswordError(t(`ErrorCodes.${error.code}`));
        setLoading(false);
        setVerifyPasswordError('We did not recognize the action that was requested');
      }
    }
  }, [userTeam, t, mode]);

  const resetPassword = async () => {
    try {
      if (values.password !== values.confirmPassword) {
        setResetPasswordError('oh snap, no match!'); // TODO: Real password error here
      } else {
        setResetPasswordError('');
      }

      await auth.confirmPasswordReset(getQueryParam('oobCode'), values.password);
      setResetPasswordCode('success');
    } catch (error) {
      setResetPasswordError(t(`ErrorCodes.${error.code}`));
      console.error(error);
    }
  };

  const { values, handleChange, handleSubmit }: any = useForm(resetPassword);

  function renderPageTitle(): string {
    const mode = getQueryParam('mode');

    if (mode === 'verifyEmail') {
      return t('SignInAuth.VerifyEmailTitle');
    } else if (mode === 'resetPassword') {
      return t('SignInAuth.ResetPasswordTitle');
    }

    return t('SignInAuth.UnknownTitle');
  }

  function renderLoading() {
    return <p>{t('General.Loading')}</p>;
  }

  function renderVerifyPasswordError() {
    return (
      <>
        <br />
        <div className="notification is-danger">{verifyPasswordError}</div>
        <div className="content">
          <h6>{t('SignInAuth.NavSuggest')}</h6>
          <ul>
            <li>
              <Link to={`/signin?lang=${i18n.language}`}>{t('SignInAuth.BackToSignin')}</Link>
            </li>
            <li>
              <Link to={`/signinHelp?lang=${i18n.language}`}>{t('SignInAuth.ResetPassword')}</Link>
            </li>
          </ul>
        </div>
      </>
    );
  }

  function renderResetPasswordError() {
    return (
      <>
        <div className="notification is-danger">{resetPasswordError}</div>
        <div className="content">
          <ul>
            <li>
              <Link to={`/signin?lang=${i18n.language}`}>{t('SignInAuth.BackToSignin')}</Link>
            </li>
            <li>
              <Link to={`/signinHelp?lang=${i18n.language}`}>{t('SignInAuth.ResetPassword')}</Link>
            </li>
          </ul>
        </div>
      </>
    );
  }

  function renderVerifyPasswordSuccess() {
    return (
      <>
        <br />
        <div className="content">
          <h4>{t('SignInAuth.SuccessTitle')}</h4>
        </div>
        <div className="notification is-info">{t('SignInAuth.SuccessMessage')}</div>
        <div className="content">
          <Link to={`/signin?lang=${i18n.language}`}>{t('SignInAuth.BackToSignin')}</Link>
        </div>
      </>
    );
  }

  function renderEmailVerified() {
    return (
      <>
        <br />
        <div className="content">
          <h4>{t('SignInAuth.VerifyEmailSuccessTitle')}</h4>
        </div>
        <div className="notification is-info">{t('SignInAuth.VerifyEmailSuccessMessage')}</div>
        <div className="content">
          <Link to={`/signin?lang=${i18n.language}`}>{t('SignInAuth.BackToSignin')}</Link>
        </div>
      </>
    );
  }

  function renderResetForm() {
    return (
      <div className="signin-form">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">{t('SignInAuth.NewPasswordLabel')}</label>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder={t('SignInAuth.NewPasswordPlaceholder')}
                name="password"
                value={values.password || ''}
                onChange={handleChange}
                data-cy="newPassword"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">{t('SignInAuth.ConfirmPasswordLabel')}</label>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder={t('SignInAuth.ConfirmPasswordPlaceholder')}
                name="confirmPassword"
                value={values.confirmPassword || ''}
                onChange={handleChange}
                data-cy="confirmPassword"
              />
            </div>
          </div>

          {resetPasswordError && renderResetPasswordError()}

          <button className="button is-fullwidth is-primary" type="submit" data-cy="resetPasswordButton">
            {t('SignInAuth.ResetMyPassword')}
          </button>
        </form>
      </div>
    );
  }

  if (userTeam.team) {
    return <Redirect to={{ pathname: '/questions' }} />;
  } else {
    return (
      <>
        <div className="level signin-logo">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                <img src={logo} className="App-logo" alt="Remotely Interview Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="level signin">
          <div className="level-item">
            <div className="card">
              <div className="card-content">
                <p className="title">{renderPageTitle()}</p>
                <p className="subtitle"> </p>
                {loading && renderLoading()}
                {!loading && verifyPasswordError && renderVerifyPasswordError()}
                {!loading &&
                  !verifyPasswordError &&
                  !resetPasswordCode &&
                  mode === 'resetPassword' &&
                  renderResetForm()}
                {!loading &&
                  !verifyPasswordError &&
                  !resetPasswordCode &&
                  mode === 'verifyEmail' &&
                  renderEmailVerified()}
                {!loading && resetPasswordCode && renderVerifyPasswordSuccess()}
              </div>
            </div>
          </div>
        </div>
        <PublicLanguageChanger></PublicLanguageChanger>
      </>
    );
  }
};

export default SignInPasswordResetPage;
