import React from 'react';

const ProfileInitials = (props: { user: any; hasBackgroundDark?: boolean }) => {
  const { user, hasBackgroundDark } = props;

  const getUserInitials = () => {
    if (!user || !user.displayName) {
      return '?';
    }
    var names = user.displayName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  function renderInitials() {
    return (
      <div className={'avatar-circle ' + (hasBackgroundDark ? 'has-background-primary-dark' : '')}>
        <span className="initials">{getUserInitials()}</span>
      </div>
    );
  }

  return (
    <>
      {!user && null}
      {user && renderInitials()}
    </>
  );
};

export default ProfileInitials;
