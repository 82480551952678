import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getSubscriptionData, ISubscription, redirectToStripePortal, updateUserWithPhoto } from '../firebase';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../stores/user/selectors';
import Resizer from 'react-image-file-resizer';
import { Link } from 'react-router-dom';
import { toast } from 'bulma-toast';

// TODO: Cancel button. Reset form and message
// TODO: Save. Message. Not redirect? Probably not.

const ProfilePage = () => {
  const user = useSelector(getUser);
  const history = useHistory();
  const [profile, setProfile] = useState<any>();
  const [subscription, setSubscription] = useState<ISubscription>();
  const [saving, setSaving] = useState<boolean>(false);
  const [redirecting, setRedirecting] = useState(false);
  const { t, i18n } = useTranslation('translation');

  const languages = Object.keys(i18n.options.resources);

  useEffect(() => {
    if (!user || !user.team) {
      return;
    }

    getProfileLocal();
    async function getProfileLocal() {
      const teamId = user.isTeamMember ? user.team : user.uid;
      const localSubscription = await getSubscriptionData(teamId);
      setSubscription(localSubscription);
      setProfile({ ...user });
    }
  }, [user]);

  const wholeNumberToCurrency = (amount: number, currency: string) => {
    if (amount) {
      amount *= 0.01;
    }
    var formatter = new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency.toUpperCase() });
    return formatter.format(amount);
  };

  const formatDate = (subscriptionDate: Date) => {
    return new Intl.DateTimeFormat(i18n.language, {
      month: 'short',
      day: 'numeric'
    }).format(subscriptionDate);
  };

  const handleChange = ({ target }: any) => {
    let { name, type } = target;
    let value = type === 'checkbox' ? target.checked : target.value;

    setProfile({ ...profile, [name]: value });
  };

  const handleFileChange = async ({ target }: any) => {
    let { name, files } = target;

    if (files && files.length > 0) {
      Resizer.imageFileResizer(
        files[0],
        300,
        300,
        'JPEG',
        100,
        0,
        uri => {
          const url = URL.createObjectURL(uri);
          profile.photoURL = url;
          profile.photoName = 'profile.jpg'; //files[0].name;
          profile.photoTemp = uri;
          setProfile({ ...profile, [name]: target.value });
        },
        'blob'
      );
    } else {
      setProfile({ ...profile, [name]: target.value });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!profile.displayName) {
      return;
    }

    try {
      setSaving(true);
      await updateUserWithPhoto(user.uid, profile);

      if (i18n.language !== profile.language) {
        i18n.changeLanguage(profile.language);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
      toast({ message: t('Profile.SaveMessage'), type: 'is-success', position: 'top-center', dismissible: true, pauseOnHover: true, duration: 6000, animate: { in: 'fadeIn', out: 'fadeOut' } });
    }
  };

  const cancelEdit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setProfile({ ...user });
  };

  const handleEditSubscription = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setRedirecting(true);
    await redirectToStripePortal();
    // setRedirecting(false);
  };

  const handleUpgrade = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    history.push('/upgrade');
  };

  function renderSubscriptionInfo() {
    return (
      <>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{t('Profile.SubscriptionLabel')}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control mock-input-content is-expanded">{subscription.productName}</div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{t('Profile.IntervalLabel')}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control mock-input-content is-expanded">{t(`Profile.${subscription.interval}`)}</div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">
              {subscription.cancel_at ? t('Profile.CancelLabel') : t('Profile.NextInvoiceLabel')}
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control mock-input-content is-expanded">
                {!subscription.cancel_at &&
                  wholeNumberToCurrency(subscription.unit_amount * subscription.quantity, subscription.currency) +
                    t('Profile.On') +
                    formatDate(subscription.current_period_end)}
                {subscription.cancel_at && formatDate(subscription.cancel_at)}
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{t('Profile.TeamSize')}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control mock-input-content is-expanded">{subscription.quantity}</div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderSubscriptionForMember() {
    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{t('Profile.SubscriptionHeader')}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div>
              {t('Profile.MemberMessage')}
              <br />
              <br />
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{t('Profile.SubscriptionLabel')}</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control mock-input-content is-expanded">{subscription.productName}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderSubscriptionForOwner() {
    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{t('Profile.SubscriptionHeader')}</p>
        </header>
        <div className="card-content">
          <div className="content">{renderSubscriptionInfo()}</div>
        </div>
        <footer className="card-footer">
          <button className="is-link card-footer-item" onClick={handleEditSubscription}>
            {subscription.cancel_at ? t('Profile.RenewSubscription') : t('Profile.EditSubscription')}
          </button>
        </footer>
      </div>
    );
  }

  function renderSubscriptionCanceled() {
    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{t('Profile.SubscriptionHeader')}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div>
              {t('Profile.ReactivateMessage')}
              <br />
              <br />
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{t('Profile.SubscriptionLabel')}</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control mock-input-content is-expanded">{t('Upgrade.PlanFreeTitle')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="card-footer">
          <button className="is-link card-footer-item" onClick={handleUpgrade}>
            {t('Profile.ReactivateButton')}
          </button>
        </footer>
      </div>
    );
  }

  function renderSubscriptionUpgrade() {
    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{t('Profile.SubscriptionHeader')}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div>
              {t('Profile.UpgradeMessage')}
              <br />
              <br />
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{t('Profile.SubscriptionLabel')}</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control mock-input-content is-expanded">{t('Upgrade.PlanFreeTitle')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="card-footer">
          <button className="is-link card-footer-item" onClick={handleUpgrade}>
            {t('Profile.UpgradeButton')}
          </button>
        </footer>
      </div>
    );
  }

  function renderProviderInfo() {
    return (
      <div className="field">
        <label className="label">{t('Profile.ProviderLabel')}</label>
        <div className="control is-expanded">
          <span className="mock-input-content">{user.providerId}</span>
        </div>
      </div>
    );
  }

  function renderPasswordChange() {
    return (
      <div className="field">
        <label className="label">{t('Profile.PasswordLabel')}</label>
        <div className="control is-expanded">
          <span className="mock-input-content">
            <span>
              ********
              <Link to={`/profilePassword`}>{t('Profile.UpdatePassword')}</Link>
            </span>
          </span>
        </div>
      </div>
    );
  }

  if (!profile || !subscription) {
    return null;
  }

  if (redirecting) {
    return (
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="box">
                <h4 className="title is-4 has-text-centered">{t('Upgrade.ConnectingToStripe')}</h4>
                <h5 className="title is-5 has-text-centered">{t('Upgrade.ThanksForPatience')}</h5>
                <div className="has-text-centered">
                  <progress className="progress is-small is-primary" max="100">
                    15%
                  </progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <h1 className="title is-2">{t('Profile.ProfileTitle')}</h1>
        <div className="columns">
          <div className="column is-half">

            <div className="field">
              <label className="label">{t('Profile.EmailLabel')}</label>
              <div className="control is-expanded">
                <span className="mock-input-content">{user.email}</span>
              </div>
            </div>

            {user.providerId !== null && user.providerId !== 'password' && renderProviderInfo()}
            {user.providerId !== null && user.providerId === 'password' && renderPasswordChange()}

            <div className="field">
              <label className="label">{t('Profile.NameLabel')}</label>
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  name="displayName"
                  value={profile.displayName || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">{t('Profile.LanguageLabel')}</label>
              <div className="control select is-fullwidth">
                <select name="language" value={profile.language || ''} onChange={handleChange}>
                  {languages.map((l: any) => (
                    <option key={l} value={l}>
                      {t(`Languages.${l}`)}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="field">
              <label className="label">{t('Profile.ProfilePhoto')}</label>
              <div className="control">
                <figure className="is-square">
                  {profile.photoURL && <img src={profile.photoURL} alt="Profile" />}
                </figure>
              </div>
            </div>

            <div className="field file has-name is-fullwidth is-right">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept="image/*"
                  name="photoFile"
                  value={profile.photoFile || ''}
                  onChange={handleFileChange}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">{t('Profile.FileLabel')}</span>
                </span>
                <span className="file-name">{profile.photoName}</span>
              </label>
            </div>

            <div className="field is-grouped">
              <div className="control">
                <button type="submit" className={`button is-link ${saving ? 'is-loading' : ''}`}>
                  {t('General.SaveButton')}
                </button>
              </div>
              <div className="control">
                <button className="button is-text" onClick={cancelEdit}>
                  {t('General.CancelButton')}
                </button>
              </div>
            </div>
          </div>
          <div className="column is-half">
            {user.isTeamMember && renderSubscriptionForMember()}
            {!user.isTeamMember && subscription.status === 'canceled' && renderSubscriptionCanceled()}
            {!user.isTeamMember && subscription.status === 'active' && renderSubscriptionForOwner()}
            {!user.isTeamMember && subscription.id === 'demo' && renderSubscriptionUpgrade()}
          </div>
        </div>
      </section>
    </form>
  );
};

export default ProfilePage;
