import React, { useEffect } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguage } from './userLanguage';

const PublicRoute = ({ component: Component, path, ...rest }: RouteProps) => {
  const { i18n } = useTranslation('translation');

  useEffect(() => {
    i18n.changeLanguage(getLanguage());
  }, [i18n, path]);

  const render = (props: RouteComponentProps<any>): React.ReactNode => <Component {...props} />;

  return <Route path={path} render={render} {...rest} />;
};

export default PublicRoute;
