import React, { useContext, useEffect } from 'react';
import { QuestionsContext } from '../providers/QuestionsContext';
import { useTranslation } from 'react-i18next';
import { arrayToCsv } from '../utilities';
import { useSelector } from 'react-redux';
import { getUserTeam, getUser } from '../stores/user/selectors';
import { addQuestion, getTaxRates } from '../firebase';

const DebugPage = () => {
  const { t } = useTranslation('translation');
  const userTeam = useSelector(getUserTeam);
  const user = useSelector(getUser);
  const { questions, loading, error }: any = useContext(QuestionsContext);
  

  useEffect(() => {
    if (!userTeam || !questions) {
      return;
    }
  }, [user, userTeam, questions]);

  const debugTaxRates = async () => {
    const taxRates = await getTaxRates();
    console.log(taxRates);
    return taxRates;
  };

  const addQuestionHandler = async () => {
    addQuestion(userTeam, 'CY-Q-DELETE-ME');
  };

  if (error) {
    return (
      <div>
        <p>
          {t('General.PageLoadError')} {error}
        </p>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <p>{t('Questions.PageLoading')}</p>
      </div>
    );
  }
  if (!questions) {
    return null;
  }

  return (
    <section>
      <h1 className="title is-2">{t('Questions.QuestionsTitle')}</h1>
      <div className="columns">
        <div className="column is-three-quarters">
          <div className="field">
            <p className="control"></p>
          </div>
        </div>
        <div className="column">
          <button className="button is-success is-fullwidth" onClick={addQuestionHandler} data-cy="new-question-button">
            {t('Questions.NewQuestionButton')}
          </button>
        </div>
      </div>

      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>{t('Questions.QuestionsHeaderName')}</th>
            <th>{t('Questions.QuestionsHeaderTags')}</th>
            <th>{t('Questions.QuestionsHeaderActions')}</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((q: any) => (
            <tr key={q.id} data-item={q.id}>
              <td>{q.name}</td>
              <td>{Array.isArray(q.tags) ? arrayToCsv(q.tags) : q.tags}</td>
              <td>&nbsp;</td>
            </tr>
          ))}
        </tbody>
      </table>
    
      <div>
        Environment: { process.env.NODE_ENV }
      </div>

      <div>
        <div>Tax Rates</div>
        <div><pre>{JSON.stringify(debugTaxRates(), null, 2) }</pre></div>
      </div>
    </section>
  );
};

export default DebugPage;