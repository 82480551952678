import React from 'react';
import ReactStars from 'react-stars';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { handleQuestionChange } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestion } from './store/selectors';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import StarNumber from '../../components/StarNumber';
import AnimateHeight from 'react-animate-height';
import * as Showdown from 'showdown';
import { showdownConverterOptions } from '../../utilities';

type TInterviewQuestionProps = {
  index: number;
  questionId: string;
  selectedQuestionId: string;
  setSelectedQuestionId: (id: string) => void;
  lastModifiedDate: Date;
  setLastModifiedDate: (dt: Date) => void;
};

const converter = new Showdown.Converter(showdownConverterOptions);

export const InterviewQuestion = ({
  index,
  questionId,
  selectedQuestionId,
  setSelectedQuestionId,
  lastModifiedDate,
  setLastModifiedDate
}: TInterviewQuestionProps) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const question = useSelector(getQuestion(questionId));

  const getExpandedItemHeight = (isExpanded: boolean) => {
    return isExpanded ? 'auto' : 0;
  };

  const handleChange = ({ target }: any) => {
    const { name, value } = target;

    dispatch(handleQuestionChange({ ...question, [name]: value }));
    setLastModifiedDate(new Date());
  };

  const handleStarChange = (value: any) => {
    dispatch(handleQuestionChange({ ...question, candidateRating: value }));
    lastModifiedDate = null;
    setLastModifiedDate(new Date());
  };

  return (
    <div className="card is-fullwidth question" key={question.id}>
      <header
        className="card-header"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          setSelectedQuestionId(selectedQuestionId === question.id ? '' : question.id);
        }}
      >
        <p className="card-header-title" data-cy={`itemsSelectQuestion${index}`}>
          {question.name}
        </p>
        <a className="card-header-icon" href="#test">
          <StarNumber value={question.candidateRating} />
        </a>
        <a className="card-header-icon card-toggle" href="#test">
          {selectedQuestionId === question.id ? <Icon icon={faChevronDown} /> : <Icon icon={faChevronRight} />}
        </a>
      </header>
      <AnimateHeight duration={500} height={getExpandedItemHeight(selectedQuestionId === question.id)}>
        <div className="card-content">
          <div className="field">
            <label className="label">
              <strong>{t('InterviewInProgress.QuestionLabel')}</strong>
            </label>
            <div
              className="control is-expanded view-question"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(question.question) }}
            ></div>
          </div>

          {/* Consider html sanitizer: https://github.com/cure53/DOMPurify */}

          <div className="field">
            <label className="label">
              <strong>{t('InterviewInProgress.AnswerLabel')}</strong>
            </label>
            <div
              className="control is-expanded view-answer"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(question.answer) }}
            ></div>
          </div>

          <div className="field">
            <label className="label">{t('InterviewInProgress.CandidateResponse')}</label>
            <div className="control is-expanded">
              <textarea
                className="textarea"
                name="candidateResponse"
                data-cy={`candidateResponse${index}`}
                value={question.candidateResponse || ''}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>

          <div className="field">
            <label className="label">{t('InterviewInProgress.Rating')}</label>
            <div className="control is-expanded star-rating">
              <ReactStars
                size={32}
                count={10}
                half={false}
                className={`candidate-rating candidateRating${index}`}
                onChange={handleStarChange}
                value={question.candidateRating}
              />
              <span
                onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                  event.preventDefault();
                  handleStarChange(null);
                }}
              >
                <Icon icon={faBan} size="lg" />
              </span>
            </div>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};
