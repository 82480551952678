import React from 'react';

interface INavbarBurger {
  toggleMenu(): void;
  active: boolean;
}

const NavbarBurger = (props: INavbarBurger) => {
  const { toggleMenu, active } = props;

  return (
    <button
      onClick={toggleMenu}
      className={'navbar-burger burger ' + (active ? 'is-active' : '')}
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export default NavbarBurger;
