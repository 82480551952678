import React, { useContext, useEffect, useState } from 'react';
import { match as Imatch } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router';
import { getQuestion, removeQuestion } from '../firebase';
import { TemplatesContext } from '../providers/TemplatesContext';
import { arrayToCsv, showdownConverterOptions } from '../utilities';
import { useTranslation } from 'react-i18next';
import Notification from '../components/Notification';
import { useSelector } from 'react-redux';
import { getUserTeam } from '../stores/user/selectors';
import { QuestionProps } from '../types';
import * as Showdown from 'showdown';

const QuestionDeletePage = () => {
  const userTeam = useSelector(getUserTeam);
  const [question, setQuestion] = useState<QuestionProps>();
  const { templates }: any = useContext(TemplatesContext);
  const { t } = useTranslation('translation');
  const history = useHistory();
  const match: Imatch<{ id: string }> = useRouteMatch();
  const converter = new Showdown.Converter(showdownConverterOptions);

  useEffect(() => {
    if (!userTeam) {
      return;
    }

    getQuestionLocal();
    async function getQuestionLocal() {
      let [question] = await Promise.all([getQuestion(userTeam, match.params.id)]);
      setQuestion(question);
    }
  }, [match.params.id, userTeam]);

  if (!question || !templates) {
    return null;
  }

  return (
    <section>
      <h1 className="title is-2">{t('Question.QuestionTitle')}</h1>
      <Notification value={t('Question.DeleteConfirm')} />
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">{t('Question.NameLabel')}</label>
            <div className="control is-expanded mock-input">{question.name}</div>
          </div>

          <div className="field">
            <label className="label">{t('Question.TagsLabel')}</label>
            <div className="control is-expanded mock-input">{arrayToCsv(question.tags)}</div>
          </div>

          <div className="field is-hidden2">
            <label className="label">{t('Interview.PositionLabel')}</label>
            <div className="control select is-fullwidth is-multiple mock-input">
              {templates
                .filter((t: any) => {
                  return question.templates.includes(t.id);
                })
                .map((t: any) => (
                  <div key={t.id}>{t.name}</div>
                ))}
            </div>
          </div>

          <div className="field">
            <label className="label">{t('Question.QuestionLabel')}</label>
            <div
              className="control is-expanded mock-input"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(question.question) }}
            ></div>
          </div>

          <div className="field">
            <label className="label">{t('Question.AnswerLabel')}</label>
            <div
              className="control is-expanded mock-input"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(question.answer) }}
            ></div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-link"
                onClick={async (event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  await removeQuestion(question.id, userTeam);
                  history.push('/questions');
                }}
              >
                {t('General.Delete')}
              </button>
            </div>
            <div className="control">
              <button
                className="button is-text"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  history.push('/questions');
                }}
              >
                {t('General.CancelButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionDeletePage;
