import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavbarItem from './NavBarItem';
import NavbarBurger from './NavBarBurger';
import { signOut } from '../firebase';
import { getUser } from '../stores/user/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProfileCard from './ProfileCard';

interface IProps {
  logo: any;
  pages: any;
  profile: any;
  team: any;
}

const Navbar: React.FC<IProps> = ({ logo, pages, profile, team }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [activeProfileMenu, setActiveProfileMenu] = useState(false);
  const user = useSelector(getUser);
  const { t } = useTranslation('translation');

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const setMenu = (isVisible: boolean) => {
    setActiveMenu(isVisible);
  };

  const toggleProfileMenu = () => {
    setActiveProfileMenu(!activeProfileMenu);
  };

  const toggleProfileMenuDelayed = () => {
    setTimeout(() => {
      setActiveProfileMenu(false);
    }, 200);
  };

  if (user.team) {
    return (
      <nav className={`navbar is-fixed-top`}>
        <div className="navbar-brand">
          <Link to={logo.route} className="navbar-item is-capitalized">
            <img src={logo.logo} className="App-logo" alt={logo.text} style={{ width: '30px' }} />
          </Link>
          <NavbarBurger active={activeMenu} toggleMenu={toggleMenu} />
        </div>
        <div className={`navbar-menu ${activeMenu ? 'is-active' : ''}`}>
          <div className="navbar-start">
            {user.team
              ? pages.map((page: any) => (
                  <NavbarItem text={page.text} route={page.route} onClick={setMenu} key={page.route} />
                ))
              : null}
          </div>
          <div className="navbar-end">
            {user.team ? (
              <div
                className={'navbar-item has-dropdown navbar-profile ' + (activeProfileMenu ? 'is-active' : '')}
                onBlur={() => toggleProfileMenuDelayed()}
              >
                <button onClick={toggleProfileMenu} className={'navbar-link navbar-profile-button'}>
                  <ProfileCard user={user} isCompact={true} hasBackgroundDark={false}></ProfileCard>
                </button>

                <div className="navbar-dropdown is-right">
                  <NavbarItem text={profile.text} route={profile.route} onClick={setMenu} />
                  <NavbarItem text={team.text} route={team.route} onClick={setMenu} />
                  <NavbarItem text={'Help'} route={'/help'} onClick={setMenu} />
                  {user.role !== 'basic' && !user.isTeamMember && (
                    <NavbarItem text={'Upgrade'} route={'/upgrade'} onClick={setMenu} />
                  )}
                  <hr className="navbar-divider" />
                  <a href="/" data-cy="logoutButton" className="navbar-item" onClick={signOut}>
                    {t('General.SignOut')}
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </nav>
    );
  }
  return null;
};

export default Navbar;
